import { ERROR_KEYS } from '@/shared/constants';

export const en = {
  translation: {
    addField: 'Add field',
    selectUser: 'Select User',
    paperBusinessCard: 'Paper business card',
    imageSizeInfo: {
      coverImage: 'Aspect ratio: 16:9. Recommended resolution: 1920x1080px.',
      logoImage: 'Any image format. Recommended minimum resolution for the smaller side: 300px.',
      profileImage:
        'Aspect ratio: 1:1. Cropped as a circle. Recommended minimum resolution per side: 600px.',
    },
    quotaExceeded: 'Quota exceeded. Contact sales or wait until the quota resets.',
    paperCards: 'Paper business cards',
    profilePicture: 'Profile Picture',
    colSortMenu: {
      dialogTitle: 'Customize table columns',
      description: 'Here you can change the order of the table columns or hide columns entirely.',
      showColumnHeader: 'Show column',
    },
    ariaProfileFile: 'Profile file',
    ariaProfileLink: 'Profile link',
    ariaSelectStartDay: 'Select {{date}} as start of the date range',
    ariaSelectEndDay: 'Select {{date}} as end of the date range',
    ariaSelectMonth: 'Select month',
    ariaSelectYear: 'Select year',
    ariaMoveMonthBack: 'Navigate back by one month',
    ariaMoveMonthForward: 'Navigate forward by one month',
    ariaFrom: 'From',
    ariaTo: 'To',
    ariaHeaderDateRangePicker: 'Date Range Selector',
    ariaSelectPredefinedRange: 'Select pre-defined date range: ',
    ariaHelpTooltip: 'Help',
    ariaInfoTooltip: 'Info',
    ariaGdprConsent: 'Toggle GDPR consent',
    ariaAddProfilePicture: 'Add a profile picture',
    ariaEditProfilePicture: 'Edit profile picture',
    ariaEditContactDetails: 'Edit contact details',
    ariaGoBack: 'Go back',
    ariaShowAllSharingOptions: 'Show all options for sharing your profile',
    ariaAcademySwiperPreviousVideo: 'Previous academy video',
    ariaAcademySwiperNextVideo: 'Next academy video',
    ariaNavHeaderProfile: 'Toggle single user profile pages navigation',
    ariaNavHeaderAdmin: 'Toggle admin pages sub-navigation',
    ariaNavHeaderTeamSettings: 'Toggle team settings sub-navigation',
    ariaHeaderAdminDropdown: 'Open account settings menu',
    ariaHeaderHelpDropdown: 'Open help menu',
    ariaAddLink: 'Add link',
    ariaAddFile: 'Add file',
    onlyPreview: 'This is only a preview',
    ariaCollapseTo_one: 'Collapse this list to one item',
    ariaCollapseTo_other: 'Collapse this list to {{count}} items',
    ariaExpandFor_one: 'Expand contact details to reveal one more item',
    ariaExpandFor_other: 'Expand contact details to reveal {{count}} more items',
    ariaOpenCookieSettings: 'Open cookie settings',
    adminDisabledEditRight: 'Your administrator has disallowed editing here.',
    ariaDigitalBusinesscardOf: 'Digital Business Card of',
    ariaLogoImage: 'Company Logo',
    ariaProfilePicture: 'Profile picture',
    ariaOpenToEnlarge: 'You can open the image in a modal',
    ariaBannerImageOption: 'Image option {{n}} for the profile banner',
    ariaBannerImageOptionsLabel: 'Choose an image from the options as a profile banner.',
    addAnotherUser: 'Add another user',
    livePreview: 'Live Preview',
    sendViaEmail: 'Send via Email',
    ariaLogoOf: 'Logo of {{name}}',
    teamSettingsSubmenu: 'Team settings - opens team settings submenu',
    default: 'Default',
    template: 'Template',
    embed: 'Embed',
    bulkEdit: 'Edit',
    bulkEditProfiles: 'Bulk Edit',
    copied: 'Copied!',
    back: 'Back',
    disconnect: 'Disconnect',
    done: 'Done',
    internalContactPerson: 'Internal Contact Person',
    generalDesign: 'General Design',
    defaultContent: 'Default Content',
    contentTemplate: 'Content Templates',
    editBio: 'Edit Bio',
    fileupload: 'File upload',
    value: 'Value',
    selectField: 'Select Field',
    override: 'Override',
    card: 'NFC-Card',
    addFileDialog: {
      title: 'Title',
      videoUrl: 'Video-Link',
      modeFile: 'File Upload',
      modeVideoLink: 'Video Embed',
      embedVideo: {
        label: 'Embed Video',
        tooltip: 'By embedding the video, it can be directly viewed from within the profile.',
        placeholder: 'Paste the video URL (from Youtube, Vimeo, etc.)',
      },
      embedVideoAlert: {
        title: 'Embed a video',
        content: 'You can embed a video directly from different sources (YouTube, Vimeo, etc.).',
        helpReference: 'See <0>this page</0> for more information.',
      },
      error: {
        invalidEmbedUrl: 'Not a valid video link',
      },
    },
    upgradeNow: 'Upgrade now',
    contactSales: 'Contact Sales',
    testBusinessSolution: 'Business Solution',
    select: 'Select',
    standard: 'Standard',
    fillRequired: 'Fill out the required fields',
    navSidebar: {
      ariaGoHome: 'Go to the home page',
      teamSettings: {
        ariaGoBack: 'Back to the main navigation menu',
      },
      collapseButton: {
        aria: {
          label: 'Collapse/Extend Sidebar',
        },
      },
    },

    addCover: 'Add Cover Image',
    addLogo: 'Add Logo',
    addImage: 'Add Image',
    addAvatar: 'Add Profile Image',
    add: 'Add',
    note: 'Note',
    at: 'at',
    preferredLang: 'Language',
    admin: 'Admin',
    nonAdmin: 'End user',
    controlVisibility: 'Control Visibility',
    scanCard: 'Scan Card',
    scanCardShort: 'Scan',
    search: 'Search',
    noSearchOptions: 'No search options',
    customizeTable: 'Customize Table',
    upgradeToContinue: 'For this action you need to upgrade first',
    deselectAll: 'Deselect All',
    selectAll: 'Select All',
    namePrefix: 'Name Prefix',
    namePrefixHelper: 'e.g. Dr., Prof.',
    academicDegree: 'Academic Degree',
    academicDegreeHelper: 'e.g. Ing., B.Sc.',
    academicDegree2: 'Academic Degree 2',
    academicDegree2Helper: 'Line 2',
    toPaperCardOrderForm: 'To the paper business card order form',
    close: 'Close',
    help: 'Help',
    gdpr: 'GDPR',
    gdprLegalBasis: 'GDPR',
    addressee: 'Addressee',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    postCode: 'Postcode',
    city: 'City',
    country: 'Country',
    address: {
      addressee: 'Addressee',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      postCode: 'Postcode',
      city: 'City',
      country: 'Country',
      label: 'Address Label',
      2: {
        addressee: '2. Addressee',
        addressLine1: '2. Address Line 1',
        addressLine2: '2. Address Line 2',
        postCode: '2. Postcode',
        city: '2. City',
        country: '2. Country',
        label: '2. Address Label',
      },
    },
    address1: 'Address 1',
    address2: 'Address 2',
    editDisabledTooltip:
      "The admin has restricted this function. You can find you admin's contact information in the help button at the top right.",
    editDisabledTooltipWithEmail: 'Only an admin can edit this. Please contact {{email}}.',
    scanner: 'Scanner',
    stillMultiAssigned: 'Some accounts are still assigned to more than one unit',
    editNotAllowed: 'Your administrator has not allowed editing this setting for your unit.',
    onlyExt: 'Only ',
    unsavedChangesText: 'You may have unsaved changes. Continue?',
    featureNotAllowed: 'Upgrade to enable this feature',
    upgrade: 'Upgrade',
    openLinkInNewTab: 'Open Link in new Tab',
    needHelp: 'Need help?',
    confirm: 'Confirm',
    finish: 'Finish',
    apply: 'Apply',
    duplicate: 'Duplicate',
    deleteSettings: 'Delete Unit Settings',
    settingDeleteConfirmation:
      'Do you really want to delete the unit settings? The settings will then be synchronized again with the global organization settings.',
    settingDeleteConfirmationTitle: 'Delete Unit Settings',
    resetSettings: 'Reset',
    settingResetConfirmation:
      'Do you really want to completely reset the settings to their initial state? All unit settings will also be deleted.',
    settingResetConfirmationTitle: 'Reset to Initial Settings',
    setupSettings: {
      title: "Currently this unit's settings are in sync with the organization settings.",
      description:
        "You can set special settings for this unit, but they won't be in sync with the organization settings anymore.",
      buttonText: 'Set up unit settings +',
    },
    upgradedOnMobile: {
      title: 'Welcome to Lemontaps Teams 🎉',
      text: 'To guarantee you a good and safe user experience, it is only possible to use the admin management view and functionalities on the desktop. Therefore, log in on the desktop right away to access the new features.',
    },
    wallet: {
      description:
        'Add Lemontaps to your {{company}} Wallet and share your contact using the QR code in the wallet.',
      step1: 'Select the QR code type',
      step2: 'Download your business card into your wallet',
      hint: {
        title: 'Add to {{device}} device wallet directly?',
        p1: 'First, scan the following QR-Code to log into Lemontaps on your {{device}} device',
        p2: 'Then, go to "Share profile" -> "Wallet" and click on the "Add to {{company}} Wallet" button on your {{device}} device.',
        browsers:
          '⚠️ The wallet functionality may not be working correctly in your browser. Use Safari or Chrome instead for generating the business card for your wallet.',
      },
    },
    toProfile: 'To my profile',
    profileOf: 'Profile of {{name}}',
    contactUs: 'Contact us',
    openOnDesktop:
      'Access to the Admin Management Platform is only possible via desktop devices, due to security measures.',
    upgradeRequestPopup: {
      title: 'Contact us to upgrade',
    },
    upgradePopup: {
      title: 'Welcome to Lemontaps Teams 🎉',
      q1: 'What is the name of your organization?',
      q2: 'How many potential users are there?',
      submit: 'Start now!',
    },
    myCards: 'NFC-Cards',
    profileOverview: 'Profile Overview',
    profileOverviewFromUnits: 'Unit Members',
    header: {
      help: 'Help',
      helpCenter: 'Help Center',
      chatWithUs: 'Chat with us',
      bookCall: 'Book 10 min Call',
      support: 'Support',
      settings: 'Account Settings',
      teamManagement: 'Team Management',
      langSelector: {
        aria: {
          label: 'Change language',
          en: 'English Language',
          de: 'Deutsch Language',
        },
      },
    },
    saved: 'Saved',
    permissionLevel: 'Permission Level',
    unexpectedError: 'Unexpected Error',
    invalidCharEntered: 'Invalid symbol',
    explainTemplatedLink: 'This is a standard link. It can be removed, but not edited.',
    explainThemeLink: 'This link was set up by an admin and cannot be edited',
    explainThemeLinkToAdmin:
      'This link is shown on every profile. It can be edited via the profile design page.',
    explainThemeFile: 'This file was set up by an admin and cannot be edited',
    explainThemeFileToAdmin:
      'This file is shown on every profile. It can be edited via the profile design page.',
    username: 'Username',
    uploading: 'Uploading',
    mobileBg: 'Smartphone Background',
    videoBg: 'Video Background',
    minute_one: '{{count}} minute',
    minute_other: '{{count}} minutes',
    addData: 'Add data',
    notEnoughLicences: 'You do not have enough licences',
    startNow: 'Start now',
    resetToDefault: 'Reset to default',
    editColumns: 'Edit columns',
    role: 'Role',
    accountDetails: 'Account Details',
    title: 'Title',
    Loading: 'Loading',
    LoadingLong: 'This might take some time',
    deleting: 'Deleting',
    saving: 'Saving',
    you: 'You',
    Dismiss: 'Dismiss',
    tryForFree: 'Try for free',
    edit: 'Edit Details',
    myLinks: 'My Links',
    addLink: 'Add a link',
    editLinks: 'Edit links',
    addAddress: 'Add an address',
    editAddress: 'Edit address',
    addFile: 'Add a file',
    editFile: 'Edit file',
    assign: 'Assign',
    unitAssign: 'Assign unit',
    unassign: 'Unassign',
    files: 'Files',
    links: 'Links',
    contactsForm: 'Contacts Form',
    additionalCover: 'Additional Cover Images',
    showContactsForm: 'Show form',
    showContactsFormAsPopup: 'Show as popup',
    website: 'Website',
    pasteToWebsite: 'Now go paste the data to your post',
    addProfile: 'Add User',
    chooseLink: 'Choose a link type',
    useTemplate: 'Use Template',
    createNewTemplate: 'Create New Template',
    selectTemplate: 'Select template',
    createOwnLink: 'Create own link',
    shareVia: 'Share with {{app}}',
    copyText: 'Copy Text',
    openLink: 'Open page',
    chooseLinks: 'Choose links',
    myFiles: 'My Files',
    showProfile: 'Show Profile',
    myProfile: 'My Profile',
    coverPicture: 'Cover Picture',
    editCoverPicture: 'Edit Cover Picture',
    editLogo: 'Edit Logo',
    chooseCoverPicture: 'Choose Cover Image',
    chooseLogoPicture: 'Choose logo',
    editHeaderLogo: 'Logo',
    editLogoTooltip:
      'Here you can upload a specific company logo to each individual employee or to a group, e.g. if there are different brands. However, this logo will be overwritten uniformly for all if a logo is set in the global setting.',
    errorUploadingImage: 'Error uploading image',
    errorUpdatingImage: 'Error updating image',
    updatingImage: 'Updating image',
    uploadingImage: 'Uploading image',
    nameLink: 'Name your link',
    enterUsername: 'Enter your username',
    enterPhone: 'Enter your phone number',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
    enterLink: 'Enter your link',
    createLink: 'Create a link',
    editLink: 'Edit the link',
    redirectMessage:
      'Redirect your business card directly to this link. Your digital business card is currently accessible.',
    redirectOffMessage:
      'Show your digital business card again when you share your profile. Currently, it is automatically redirected to this link.',
    hideMessageLink: 'Hide this link from your profile. It is currently displayed.',
    showMessageLink: 'Display this link on your profile again. It is currently hidden.',
    hideMessageFile: 'Hide this file from your profile. It is currently displayed.',
    showMessageFile: 'Display this file on your profile again. It is currently hidden.',
    nameFile: 'Name your file',
    upload: 'Upload',
    uploadNew: 'Upload new',
    browseFiles: 'Browse Files',
    deleteLink: 'Delete link',
    saveContact: 'Save contact',
    editProfile: 'Edit Profile',
    editProfiles: 'Edit profiles',
    myContactDetails: 'My Contact Details',
    contactDetails: 'Contact Details',
    submitYourContact: 'Submit your contact information and\nreceive mine by email:',
    account: 'Account',
    connectAccount: 'Connect Account to a Tap or Card',
    sendContactProfile: 'Send me your contact and\nreceive mine by email:',
    myContacts: 'My Contacts',
    addContact: 'Add new Contact',
    addContactShort: 'Contact',
    howToTap: 'How to tap a phone?',
    tapIphone: 'How To Tap Iphone',
    tapAndroid: 'How To Tap an Android',
    howtotap: 'How To Tap',
    showNow: 'Shop now',
    changePassword: 'Change Password',
    logout: 'Logout',
    nfcInfo1: '1. NFC has to be activated',
    nfcInfo2: "iPhone XR and newer: It's already automatically activated.",
    nfcInfo3:
      'iPhone 7 - Iphone X:iOS14 needs to be installed. Go to "settings" - "Control Center" and add "NFC".',
    nfcInfo4: 'Now swipe the control center and press the NFC sign to tap:',
    nfcInfo5: "Phone still doesn't have NFC?",
    nfcInfo6: '2. Tap',
    nfcInfo7: "Tap it against the upper part of someone's iPhone",
    nfcInfo8: 'Activate "NFC" once in the menu from the top.',
    nfcInfo9: "If it's not already in the menu you need to first turn it on in the settings:",
    nfcInfo10: 'Settings - Connections - NFC and Payments',
    nfcInfo11: 'Settings - Device Connections - NFC',
    nfcInfo12: 'Settings - Connected devices - Connection Setting - NFC',
    nfcInfo13: 'Settings - More - Mobile Data - NFC',
    nfcInfo14: 'Link your Lemontap or Lemoncard to your profile',
    nfcInfo15: 'You can find the code on the packaging underneath your product.',
    nfcInfo16:
      'You can always buy a Lemontap or a Lemoncard later and connect it to your profile in the menu.',
    nfcInfo17: 'Tap it against the middle part of the back of someone’s Android phone',
    gotIt: 'Got it',
    accountRole: 'Permission',
    firstName: 'First Name',
    lastName: 'Last Name',
    birthday: 'Birthday',
    position: 'Position',
    company: 'Company',
    costCenter: 'Cost Center',
    division: 'Division',
    businessCardUrl: 'Business Card',
    businessCardUrls: 'Business Cards',
    profileImageUrl: 'Profile Image',
    mobilePhone: { text: 'Telephone - Mobile', label: 'Mobile' },
    workPhone: { text: 'Telephone - Work', label: 'Work' },
    homePhone: { text: 'Telephone - Home', label: 'Home' },
    hotlinePhone: { text: 'Hotline', label: 'Hotline' },
    assistantPhone: { text: 'Telephone - Assistance', label: 'Assistance' },
    phone: 'Phone',
    assistantEmail: { text: 'Assistance Email', label: 'Assistenz' },
    assistantName: { text: 'Assistance Name', label: 'Assistenz' },
    assistantGeneric: { text: 'Assistance Misc', label: 'Assistenz' },
    workFax: { text: 'Fax', label: 'Fax' },
    authEmail: 'Account-Email',
    createdOn: 'Created On',
    editedAt: 'Edited On',
    email1: 'Email 1',
    email2: 'Email 2',
    email3: 'Email 3',
    workUrl: 'Homepage URL',
    bioText: 'Bio (free text)',
    update: 'Update',
    allProfilesMD: 'All Profiles',
    rowsPerPage: 'Rows Per Page:',
    searchMD: 'Search for...',
    emptySearchMD: {
      tooltip: 'Enter "#empty#" to filter for empty values or click here',
      tag: '#empty#',
    },
    editMD: 'Edit',
    editSelected: 'Edit Selected',
    editSelected_one: 'Edit Profile',
    editSelected_other: 'Edit {{count}} profiles',
    deleteSelected: 'Delete Selected',
    deleteSelected_one: 'Delete Profile',
    deleteSelected_other: 'Delete {{count}} Profiles',
    deleteBulkMessageConfirmation_one:
      "Do you want to delete this account? No data will be lost except for the account's profile information (Collected contacts and statistics will be kept).",
    deleteBulkMessageConfirmation_other:
      "Do you want to delete these {{count}} accounts? No data will be lost except for the account's profile information (Collected contacts and statistics will be kept).",
    yes: 'Yes',
    no: 'No',
    asc: 'Ascending',
    desc: 'Descending',
    sortOrder: 'Sort Order',
    sortBy: 'Sort By',
    shareProfile: {
      text: 'Share Profile',
      title: 'Share',
      wallet: {
        online: 'Online',
        offline: 'Offline',
        title: 'QR-Code',
        titleTooltip:
          'Online: Opens your digital Lemontaps profile directly in the browser.\nOffline: The offline QR-code works without internet. Scanning it opens the contact file directly, not your online Lemontaps profile. The saved contact still contains the link to your Lemontaps profile, so the other person can open it at any time.',
        buttonsTooltip:
          'Add Lemontaps to your {{company}} Wallet and share your contact using the QR code in the wallet.',
        dialog: {
          title: 'What are online and offline QR codes?',
          description:
            'Online: Opens your digital Lemontaps profile directly in the browser.\nOffline: The offline QR-code works without internet. Scanning it opens the contact file directly, not your online Lemontaps profile. The saved contact still contains the link to your Lemontaps profile, so the other person can open it at any time.',
        },
      },
      profileLink: {
        title: 'Profile Link',
        label: 'Copy Link',
        copied: 'Copied',
        shareVia: 'Share via',
        shareViaMedia: 'Share via social media',
        tip: '💡 Tip: Add the link to your email signature',
      },
      nfcCards: {
        title: 'NFC-Cards',
        description: 'You can use physical cards to share your profile via tap or QR-Code.',
      },
      qrBackgrounds: {
        title: 'QR Backgrounds',
        description: 'Design Videocall Backgrounds and Phone Lockscreens.',
      },
      shareMenu: {
        qrMobile: 'Create QR Lock Screen',
        qrDesktop: 'Create QR Video Call Background',
        nfcCard: 'NFC Card',
      },
    },
    dateDesc: 'Descending',
    dateAsc: 'Ascending',
    textDesc: 'Sort Z to A',
    textAsc: 'Sort A to Z',
    numberDesc: 'Descending',
    numberAsc: 'Ascending',
    reset: 'Reset',
    leads: {
      xSelected_one: '{{count}} contact selected',
      xSelected_other: '{{count}} contacts selected',
      xAllSelected: 'All contacts are selected',
      searchPlaceholder: 'Name, email, etc...',
      addEditNote: 'Add/Edit Note',
      leadNoteAddSuccess: 'Note added successfully',
      leadNoteAddError: 'Error adding note',
      noLeadsCollected:
        'You haven’t collected any contacts yet. Add a new contact by clicking below.',
      createDate: 'Creation Date',
      noLeadsSearchResult: 'No contacts found',
    },
    mdUnits: {
      noUnitsFound: 'No units found',
      searchPlaceholder: 'Search for unit name...',
      noUnitsAreAdded: 'You don’t have any units right now. Create a new unit by clicking below. ',
      deleteUnitsMsg_one: 'Are you sure you want to delete the selected unit?',
      deleteUnitsMsg_other: 'Are you sure you want to delete the selected {{count}} units?',
      xAllSelected: 'All units are selected',
      xSelected_one: '{{count}} unit selected',
      xSelected_other: '{{count}} units selected',
      members: 'Members',
      admins: 'Admins',
      viewMembers: 'View Members',
      manageMembers: 'Manage Members',
      addMembers: 'Add Members',
      addMembersExplainer:
        'To add members to your unit you need to visit the user profiles page, select the members and assign them to your selected unit.',
      goToUsers: 'Go to User Profiles',
      addExistingUser: 'Add existing user to unit',
      createNewUser: 'Create new user',
      deleteSuccess: 'Unit deleted successfully',
    },
    mdLeads: {
      unitsNotEmpty: 'Only empty units can be deleted',
      xUnitsSelected_one: '{{count}} selected',
      xUnitsSelected_other: '{{count}} selected',
      xAllUnitsSelected: 'All are selected',
      successCreate: 'Unit created',
      noUnit: 'No unit',
      edit: 'Edit',
      unit: 'Unit',
      status: 'Status',
      membersView: 'View members',
      view: 'View',
      loadingUnits: 'Loading Units',
      noSelectedUnits: 'There are no users in this unit yet',
      addEmployeeToUnit: 'Adding existing employees to a unit',
      editUnitHeader:
        'Go to the profiles page, select the users you want to assign to a unit, and then select the option "Assign to unit".',
      loadingUnitsError: 'Error loading units',
      unitNameExists: 'A unit with that name exists already',
      addUsersToUnit: 'Add existing users to the unit',
      noUnitSelected: 'At least one unit must be selected',
      firstCreateUnit: 'You must create a unit first',
      noRoleSelected: 'At least one role must be selected',
      unassignFromUnit: 'Unassign from unit',
      confirmRemoveUnit_one: 'Do you want to remove the selected profile from the unit?',
      confirmRemoveUnit_other: 'Do you want to remove the selected profiles from the unit?',
      removeFromUnit: 'Remove from unit',
      removingFromUnit: 'Removing from unit',
      successRemovingFromUnit: 'Removed from unit',
      toProfiles: 'To the Profiles page',
      AssignUnitError: ' Error assigning units',
      assignUnitWarning:
        'The selected accounts are assigned to the units that are selected here and removed from the units that are not selected here.',
      members: 'Members',
      deleteUnits_one: 'Are you sure you want to delete this unit?',
      deleteUnits_other: 'Are you sure you want to delete these units?',
      unitAdmins: 'Unit Admins',
      errorReadUnits: 'Error while loading units',
      errorDeleteUnits: 'Error while deleting units',
      contact: 'Contact',
      notes: 'Notes',
      multiNotes: 'Potentially multiple notes',
      connectedWith: 'Met by',
      date: 'Date',
      vcf: '.vcf',
      deletedAccount: "Account doesn't exist any more",
      accountDeleted: 'Deleted account',
      connectedAccounts: 'Met by',
      connectTo: 'Met by',
      downloadCSV: 'CSV-Export',
      editFormTitle: 'Edit Contact',
      editSuccess: 'Contact updated successfully',
      editError: "Contact couldn't be updated!",
      addFormTitle: 'Add contact',
      addSuccecss: 'Contact added successfully',
      addError: "Contact couldn't be added",
      uploadPhoto: 'Upload Photo',
      download: 'Download',
      metAt: 'Met on',
      fetchError: 'Please reload the page and try again',
      deleteContact: 'Delete contact',
      deleteSuccess: 'Contact deleted successfully',
      deleteError: "Contact couldn't be deleted",
      tooltipMultiAccountsConnected:
        'This contact has been collected by several employees. By selecting an employee in the dropdown below, you can switch between the contact information entered by each one of them.',
      export: 'Exports',
      errorSaving: 'Error while saving, at least one field is required',
      crmExport: {
        confirmDialog: {
          title: 'Export',
          text_one: 'You are about to export {{count}} contact to {{name}}.',
          text_other: 'You are about to export {{count}} contacts to {{name}}.',
          textAll: 'You are about to export all contacts to {{name}}.',
          campaignAccordionTitle: 'Assign to a campaign',
          campaignTitle: 'Do you want to assign the selected contacts to a campaign?',
          selectCampaign: 'Select a campaign',
          campaignHelper:
            "Note: you don't need to select a campaign and can just proceed with the export",
          configurationsAccordionTitle: 'Fill in required fields from CRM',
          configurationsInfo:
            'If you have required custom fields configured in your CRM, you must provide a value here, otherwise the export will fail. If you do not see a field here, click the button below to refresh.\n\nIf you are an admin, you can also configure global default values in the CRM settings page.',
          noConfigurations: 'No required fields available.',
          refreshConfigurations: 'Refresh custom fields configuration',
        },
        startSuccess: 'Export started',
        startFail: 'Error while starting the export',
        emptySelection: 'Select contacts to export',
      },
      metOn: 'Met on',
      metBy: 'Met by',
      showDeleted: 'Show deleted',
      deleteLeadsMsg_one: 'Are you sure you want to delete the selected contact?',
      deleteLeadsMsg_other: 'Are you sure you want to delete the selected {{count}} contacts?',
      crmExportStatus: 'Crm Export Status',
    },
    deletedAt: 'Deleted On',
    translations: {
      title: 'Translations',
      description:
        'Translate the digital business card profiles of your team. You can translate the standard text, contact information, link names, links and file names. You can also decide not to show information in certain languages.',
      language: {
        updateError: 'Error updating language!',
        title: 'Supported Languages',
        description:
          'The language of the profile depends on the device settings the profile is shared to. If the device language is not known the profile gets displayed in the fallback language. Set your input language to the language that you create your profiles in the admin dashboard with.',
        types: {
          input: 'Input Language',
          fallback: 'Fallback Language',
        },
        button: 'Add a Language',
        addModal: {
          title: 'Add a new Language',
          languageLabel: 'Select Language',
          typeLabel: 'Type (optional)',
        },
        notEditable:
          'You cannot remove the input or fallback language labels. Instead, select a different language to move the labels to.',
        editModal: {
          title: 'Edit Language',
          languageLabel: 'Language',
          typeLabel: 'Type (optional)',
        },
        deleteModal: {
          errorMainLangDelete:
            'You cannot delete the input or fallback languages. First, select another language as the input or fallback language.',
          title: 'You are about to remove support for a language',
          description:
            'This language will no longer be supported on profiles of your employees. Profile visitors whose devices are configured to this language will instead see the profile in the fallback language.',
        },
      },
      packs: {
        title: 'Language-Packs',
        description:
          'Download the language packs and add or modify translations. If you leave a field blank in the translation, the value of the Input Language is taken for that field. If you wish to delete a field, write “{{token}}”. Upload a zip-file containing csv-files with the same names as in the download only.',
        uploadLabel: 'Click to upload or drag and drop',
        link: 'Download current Language Pack configuration',
        notAllowedTitle: 'Ask your admin for permission',
        notAllowedBody: 'Your admin does not allow unit-admins to edit translations.',
        uploadSuccess: 'Language packs updated.',
        filename: 'language-pack',
        errors: {
          generic: 'Error',
          fileNotFound: 'File no found',
          uploadError: 'Upload failed. Check the file formats and contents.',
          invalidFilename:
            'At least one invalid file name. Name the files exactly as in the downloaded language pack.',
          api: {
            LIMIT_FILE_SIZE: 'File is too big.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_NO_DATA]: 'File could not be read.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_INVALID_FILE_TYPE]: 'Invalid file type.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_READ_ERROR]:
              'Error reading the file. The uploaded ZIP file may be corrupted.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_INVALID_FILENAMES_OR_NO_PERMISSION]:
              'Only file names that are also included in the downloaded language pack are allowed. Either incorrectly named files were uploaded, or you are not authorized to edit some of the categories.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_CSV_PARSE_ERROR]:
              '"{{filename}}": Error reading the data. Make sure that the columns and rows are formatted correctly.',
            [ERROR_KEYS.TRANSLATIONS_UPLOAD_INVALID_KEYS]:
              '"{{filename}}": Some or all translation keys are invalid.',
          },
        },
      },
    },
    errorDeletingUsers_one: 'Error while trying to delete Account',
    errorDeletingUsers_other: 'Error while trying to delete Accounts',
    errorFetchingProfiles: 'Error while fetching profiles',
    successfullyUsersDeleted_one: 'Accounts deleted successfully',
    successfullyUsersDeleted_other: 'Account deleted successfully',
    deleteAccounts_one: 'Delete Account',
    deleteAccounts_other: 'Delete Accounts',
    onlineQrCodes: 'Online QR-Codes',
    offlineQrCodes: 'Offline QR-Codes',
    employeesCsv: 'CSV',
    employeesXlsx: 'Excel',
    profileLinks: 'Profile Links',
    actionTooltips: {
      onlineQrCodes:
        'You will export a Lemontaps QR-Code which will redirect to the digital profile of the selected users.',
      offlineQrCodes:
        'You will export a Lemontaps QR-Code which will contain the vcard of the selected users.',
      csv: 'You will export all user information in the table in CSV-Format.',
      xlsx: 'You will export all user information in the table in Excel-Format.',
      links: 'You will export a CSV-File with the profile links of the selected users.',
      mobileBg:
        'You will export a QR-Code Background for the mobile lockscreen of the selected users.',
      videoBg: 'You will export a QR-Code Background for the videocall tool of the selected users.',
    },
    gettingCommonLinks: 'Getting common links...',
    commonLinksFound: 'Common links found!',
    commonLinksNotFound: 'No common links found!',
    xProfilesSelected: '{{count}} selected',
    xProfilesSelectedLong: '{{count}} profiles selected',
    xAllProfilesSelected: 'All are selected',
    bulkActions: {
      chooseActionType: 'Choose action type',
      chooseField: 'Choose field',
      value: 'Value',
      distribute: {
        title: 'Distribute via E-Mail',
        wallet: {
          tooltip:
            'Distribute Google and Apple Wallet business cards to the selected profiles via email.',
          successMsg: 'Wallet emails successfully sent.',
          errorMsg: 'Error while sending emails, please reload the page and try again.',
        },
      },
      invite: {
        title: 'Send Invitation Emails',
        confirm: 'Are you sure you want to send invitation emails to the selected profiles?',
      },
    },
    inviteSelected: 'Send invitation emails',
    inviteSelected_one: 'Send invitation email',
    inviteSelected_other: 'Send ({{count}}) invitation emails',
    bulkUpdated: 'Profiles updated',
    bulkFailed: 'Error updating profiles',
    bulkInvitedSync: 'Invitation emails sent',
    bulkInvitedAsync:
      'You will receive an e-mail as soon as the profiles have been invited. This may take a little while.',
    invited: 'Invitation email sent',
    passwordMessage: 'Your password should contain at least',
    characters: '8 characters',
    oneNumber: 'One number',
    oneSpecialCharacter: 'One special character',
    next: 'Next',
    welcomeMD: 'Welcome to the Management Platform',
    problemsMD: 'If you have any problems with the login.',
    clickHere: 'Click here!',
    welcomeBackMD: 'Welcome Back!',
    forgotMD: 'Forgot your access data?',
    resetPasswordMD: 'Reset your password',
    emailAddress: 'Email Address',
    password: 'Password',
    welcomeMessage: 'Welcome to Lemontaps',
    logIn: 'Login in',
    passwordForgot: 'Forgot your password?',
    choosePersonalLink: 'Choose your personal link',
    yourUsername: 'your username',
    signUpMessage: 'for your Lemontaps account in seconds.',
    alreadyRegistered: 'Already have an account?',
    signUp: 'Signup',
    confirmPW: 'Confirm Password',
    consentMessage:
      'By using this service you are agreeing to the terms and conditions and privacy policy.',
    firstNameONB: "Hey, welcome on board 🎉 What's your first name?",
    lastNameONB: "Hi {{firstName}} 🎉 What's your last name?",
    addProfileImageONB: 'Add your profile photo',
    changeMessage: 'You can change this later.',
    skip: 'Skip',
    addHeadlineONB: 'Add a headline about yourself',
    addHeadlineMessage:
      'This is a short description of who you are or what you do, e.g. job position, personality traits.',
    headline: 'Headline',
    addPhoneONB: 'Add your phone number',
    addPhoneMessage:
      'This phone number can be shared with your contacts. You can always change it.',
    linkLemontap: 'Link your Lemontap or Lemoncard to your profile',
    activationCodeMessage: 'You can find the code on the packaging underneath your product.',
    enterCode: 'Enter code',
    connect: 'Connect',
    continueWithoutLemontap: 'Continue without a Lemontap',
    learnHowONB: 'Learn how to tap your Lemontaps with other phones',
    connectSuccessfully:
      'You have successfully connected your Lemontaps! You can now begin to set up your profile.',
    validateAccount: 'Validate your account',
    sentEmail:
      "We've sent you an email with a link to validate your account. Please check your inbox or your spam folder.",
    failreceiveEmail: "I didn't receive the email",
    findInstructions: "Click here to always find the instructions for 'How to tap'",
    contactInfo: 'Contact Information',
    contactInfoBulkHint:
      'The data configured here will override the resp. field of the selected profiles.',
    contactInfoBulkHintDeleted:
      'The attribute you choose here will be emptied on all the selected profiles.',
    accountInfo: 'Account Information',
    german: 'German',
    english: 'English',
    language: 'Language',
    createProfile: 'Create New Profile',
    shareNFC: 'Share to phones without NFC',
    scanQRC: 'Let them scan your QR-Code which you can find in your profile',
    loginEmail: 'Login with Email',
    pleaseLogin: 'Please login with the email address and the password from the packaging.',
    pageNotFound: 'Oops! Page not found 🙈',
    weareSorry:
      'We are sorry for the incovenience . It looks like you’re trying to access a page that either has been deleted or never existed.',
    backHome: 'Back to Homepage',
    enterLinkerror: 'Please enter your link',
    nameLinkerror: 'Please name your link',
    enterUsernameerror: 'Please enter your username',
    enterMessage: 'Please enter {{subject}}',
    phoneSubject: 'a phone number',
    usernameSubject: 'a username',
    linkSubject: 'a link',
    one: '',
    nameFileerror: 'Please name your file',
    correctLink: 'Please enter a correct link',
    serverError: 'Server Error.',
    usernameCharcters: 'Username must be longer than 3 characters.',
    matchingPasswords: "Password and Confirm Password doesn't match!",
    connectMD: 'You have to connect a Lemontaps',
    errorMDMail: 'You have to type in a valid email',
    errorMDFullName: 'You have to type both first and last names',
    changesSaved: 'Changes saved!',
    codeRead: {
      title: 'Scan the QR Code of the card you want to connect',
      missed: 'Malformed QR-Code!',
      loadingMD: 'Checking if valid...',
      successMD: 'The code is valid!',
      errorMD:
        'This QR-Code is either in use or incorrect. Please contact customer support if the card is not used, but shown as incorrect.',
      btnText: 'Connect a new card',
      btnSuccessText: '1 card will be connected',
      connectHeadlineMD: 'Optional: Connect a new Card with the profile',
      connectMessageMD:
        'Scan the QR-Code on the card and the profile will be automatically connected with the card.',
      enterCodeText: 'or enter the code manually',
      switchCamera: 'Switch Camera',
      problems: 'Problems? Scan the QR-Code using your phone.',
      startNow: 'Start now',
      uploadImage: 'Upload image of QR-Code',
    },
    imageUpdatedMD: 'Image updated!',
    createdSuccessMD: 'Created successfully!',
    status: 'Status',
    statusActive: 'Active',
    statusInvited: 'Invited',
    statusUninvited: 'Not Invited',
    sendInvite: 'Send Invite',
    sendInviteTooltip:
      'Do you want to send out an invitation link automatically when creating this profile? You can also do this later.',
    resendInvite: 'Resend Invite',
    invitationMD: `Do you want to send out an invitation link automatically when creating this profile?
                       You can also do this later.`,
    cropImage: 'Position and crop your picture',
    requestDelete: 'Are you sure?',
    deleteMessageLink: 'Your link will be deleted.',
    deleteMessageFile: 'Your file will be deleted.',
    deleteMessageAddress: 'The address will be deleted.',
    genericDelete: 'This action is irreversible. Are you sure you want to continue?',
    deleteFile: 'Delete File',
    deleteIt: 'Yes, delete.',
    DBScanQR: 'Scan the QR-Code from the card with your phone',
    DBOpenlink: 'Open the scanned link and go to the URL bar',
    DBUseCharac: 'Use the characters after the slash "/" of the URL lemontaps.com/"',
    DBUseCharac2: 'as the activation code and type this code here below',
    YourPlan: 'Your Plan',
    FirstYear: 'in the first year',
    billingHeadingSecond: 'and afterwards 29.99€/year',
    active: 'Active',
    activeUppercase: 'ACTIVE',
    cancelPlan: 'cancel plan',
    mailTemplateFirst: 'Please send us an email to ',
    mailTemplateSecond: ' to cancel your subscription.',
    mailTemplateActive: ' to activate your subscription.',
    billing: 'Billing',
    onBoard: 'Hey, welcome on board!',
    yourName: 'Whats your name?',
    cardInfo: 'Add more information to your digital business card.',
    learnHowToTap: 'Learn how to tap',
    lemonTapsPhones: 'your Lemontaps with other phones',
    subscription: 'Subscription Plan',
    newCardDetected:
      'A new NFC-Card has been recognized. Would you like to link it to your profile?',
    connectCard: 'Connect NFC-Card?',
    addCodeSuccess: 'You have successfully connected your card with your profile!',
    verification: {
      verifyEmail: 'Verify your email',
      subHeader:
        'We sent a verification link to <0>{{email}}</0>.<1></1>Please check your inbox or spam folder.',
      notReceived: "Didn't receive email?",
      resendEmail: 'Resend',
      sendSuccess: 'Sent',
      sendError: 'Failed',
      wrongEmail: 'Wrong Email?',
      changeEmail: 'Change Email',
      logout: 'Logout',
      back: 'Back to login',
    },
    emailTooltipCreate:
      'This is the email address which will be used for the registration and login.',
    emailTooltipEdit: `This field only determines the email address being displayed on the profile page and the downloaded contact.
                      If you want to change the email address used to login with, please open the tab "Account" above.`,
    metaTagTitle: 'Your digital and sustainable business card',
    metaTagDescription:
      'Manage and share your contact information, links and documents efficiently and without a waste of resources! Lemontaps is the original and leading provider in Germany for digital business card!',
    metaTagProfileDescription: 'Connect with me now and check out my links and documents!',
    register: 'Register',
    newToLt: 'If you are new to Lemontaps',
    login: 'Login',
    fileUploaded: 'File uploaded successfully',
    addContactLead: 'Add a new Contact',
    leadType: 'Lead Type',
    exportToCRM: 'Export & Import to CRM',
    importToCRM: 'Import File to your CRM',
    step1: 'Step 1',
    step2: 'Step 2',
    downloadFile: 'Download File',
    chooseFileFormat: 'Choose the format you want to export.',
    downloadCSV: 'Download CSV',
    downloadXLSX: 'Download XLS',
    importInstructions: 'It is very easy - we have provided instructions for different CRMs',
    learnMore: 'Learn more',
    howToImport: 'How to import the file to your CRM',
    qrCodeBG: 'QR-Code Background',
    qrWallpaper: 'QR-Wallpaper',
    videocallBG: 'Videocall background',
    videocallTxt:
      'Easily exchange your business cards even at online events or meetings with your Lemontaps QR-Code in a videocall background. The recommended background size is at least 1500x1000.',
    bgImageTitles: {
      choosePosition: 'Choose position',
    },
    qrCodeBackground: {
      alerts: {
        noSelection: {
          title: 'No image selected',
          content: 'You first have to upload an image.',
        },
        noActionAllowed: {
          title: 'Not allowed',
          content:
            'You cannot download any QR-Code background here. Ask your admin to either upload a preset of images to choose from or to allow uploading your own images here.',
        },
      },
      type: 'Choose QR-Code Type',
      typeTooltip:
        'Online: Opens directly your digital Lemontaps profile in the browser.\n Offline: The offline QR-code works without internet. Scanning it opens the contact file directly, not your online Lemontaps profile. The saved contact still contains the link to your Lemontaps profile, so the other person can open it at any time.',
      image: 'Choose Image',
      imageTooltip:
        'Any format, min width of {{limit}}px recommended.\nTipp: Use the format of your phone!',
      download: 'Set QR-Code Design & Download',
      online: 'Online',
      offline: 'Offline',
      uploadSubtext: 'SVG, PNG, JPG or GIF (any format, minimum width: {{limit}}px)',
      howToUseVC: 'How do I use my videocall background in Zoom, Teams or Hangouts?',
      positions: {
        top: 'Top',
        middle: 'Middle',
        bottom: 'Bottom',
        left: 'Left',
        right: 'Right',
        default: 'Default',
      },
      mobile: {
        title: 'QR Phone Background',
        header:
          'Always have your Lemontaps profile handy on a QR-Code on your Smartphone lockscreen.',
        label: 'Phone Background',
      },
      videocall: {
        title: 'QR Videocall Background',
        header:
          'Easily exchange your business cards even at online events or meetings with your Lemontaps QR-Code in a videocall background.',
        label: 'Videocall Background',
      },
    },
    download: 'Download',
    imageUpload: 'Upload Image',
    generalQRImgError: 'Please reload the page and try again',
    uploadImgError: 'Please upload an image file (.jpg, .jpeg, .png)',
    uploadImgSuccess: 'Image successfully uploaded',
    downloadImgSuccess: 'Download was successfully',
    mobileBG: 'Mobile background',
    uploadedImgSizeErr: `The image must have a width of at least {{x}}px.`,
    uploadedMobileImgErr: 'The image must be vertical with a minimum {{x}}px width.',
    notes: 'Notes',
    writeNoteHere: 'Write your notes here',
    contactInformation: 'Enter Contact Information',
    scanBusinessCard: 'Upload business card image',
    uploadPhoto: 'Upload Photo',
    dataWillBeEntered: 'Data must be entered',
    editContact: 'Edit Contacts',
    leadSegment: 'Lead Segment',
    or: 'or',
    createInProgress: 'Create feature is in progress',
    editInProgress: 'Edit feature is in progress',
    missingInputPrompt: 'You have to type in a {{param}}',
    fullName: 'Full Name',
    firstAndLastName: 'First & Last Name (in one row)',
    name: 'name',
    email: 'Email',
    workEmail: 'Email 2',
    workEmail2: 'Email 3',
    businessCardLink: 'Business Card Link',
    correctEmail: 'Please type in a correct email address',
    succAdded: 'Successfully added!',
    consentToData: 'Please give your consent according to the GDPR laws.',
    shareBusinessCard: 'Share your business card with {{name}}',
    shareContactDetails: 'Share your contact details with {{name}}',
    retakePic: 'Retake picture',
    gdprConsent: {
      pt1: 'By submitting I confirm that I have read the',
      pt2: 'and that I consent to the processing of my contact details in the contact management of the Lemontaps user, as well as that of their company, if applicable',
      pt3: 'You can revoke this consent at any time.',
      gdpr: 'privacy policy',
    },
    privacyPolicy: 'GDPR',
    termsAndConditions: 'T&C.',
    and: ' and ',
    Send: 'Send',
    saveContactOf: `Save {{name}} contact`,
    modalEmailPlaceholder: 'Send this contact to your email',
    dataDisclaimer: 'We do not sell or share your data.',
    lgFollowUp_contSave: 'Do you also want to save {{name}} contact information?',
    lgFollowUp_contConn: 'Do you also want to share your contact information with {{name}}?',
    lgFollowUp_doneSave: 'We have sent you an email with {{name}} contact card!',
    lgFollowUp_doneSaveDown: 'Successfully downloaded {{name}} contact!',
    lgFollowUp_doneConn: 'Your contact information was successfully sent to {{name}}!',
    leadSuccess: 'Your contact was digitally transferred!',
    leadSaveSuccess: 'Saved successfully',
    leadError: 'Error while saving.',
    deleteLead: 'Delete Contact',
    deleteLeadSuccess: 'Contact deleted.',
    deleteLeadError: 'Error while deleting.',
    deleteLeadMsg: 'Your contact will be deleted.',
    fileTypeNotSupported: 'File type not supported.',
    uploadRestrictedTo: 'Please upload jpg, jpeg, png, gif files.',
    maxUpload: 'Max. upload size should be {{mb}} MB.',
    EditBioSuccess: 'Data save successfully.',
    editDetails: 'Edit Details',
    profiles: 'Profiles',
    units: 'Units',
    unit: 'Unit',
    mainUnit: 'Main Unit',
    subUnit: 'Sub Unit',
    mainUnitTooltip: `The main unit is decisive for unit-specific settings. If, for example, individual profile designs are defined for all units, then the profile will appear with its main unit's profile design.`,
    otherUnits: 'Other Units',
    otherUnitsTooltip: `Accounts can be assigned to multiple units. Thus, in addition to the account's main unit, other units can be defined. A unit admin can always edit all profiles in all units in which he/she is a member.`,
    unitsText: 'Group users into units. These can also be managed by sub-admins themselves.',
    addUnit: 'Add unit',
    editUnit: 'Edit unit',
    searchUnits: 'Search Units',
    createUnit: 'Create unit',
    noUnitData: 'There are currently no units available',

    guides: 'Get Started',
    guideHeader: 'Los geht’s mit Lemontaps',
    guideSubHeader:
      'Hier finden Sie einer Übersicht zu Guides und Hilfen, wie Sie Lemontaps einrichten und benutzen können.',
    connectionsSubHeader:
      'Find all captured contacts/leads of all employees in one place, which were collected using the contact form at the bottom of the public profile.',
    // MD Customization strings start
    companySettings: 'Company Settings',
    globalCompanyData: 'Global Company Data',
    globalCompanyDataSub: 'Add and manage your default company data.',
    globalCompanyAddButton: 'Add Data',
    organisationPageHeader: 'Organization',
    filesPageHeader: 'Files',
    addressesPageHeader: 'Addresses',
    footerPageHeader: 'Footer',
    linksPageHeader: 'Links',
    qrCodePageHeader: 'QR-Code Backgrounds',
    contactFormPageHeader: 'Contact Form',
    standardData: 'Data Templates',
    emailTemplatesPageHeader: 'Email Templates',
    teamSettings: 'Team Settings',
    leadForm: 'Contact Form',
    customizationSub: 'Customize your Lemontaps Platform according to your company’s needs.',
    customizeDesign: 'Customize Design',
    general: 'General',
    organisation: {
      title: 'Organization',
      name: 'Name of your organization',
      nameTooltip: 'The name appears e.g. in the email subjects, or in the browser tab title.',
      nameRequired: 'Organization Name is required.',
      nameFailed: 'Failed to update Organization Name.',
      internalDesign: 'Internal design of the platform',
      internalDesignFailed: 'Failed to update Internal Design.',
      bannerUpgradeNow: 'Upgrade now.',
      bannerText: 'The Internal Design is not available in your current package.',
      lightBackground: 'Logo for light backgrounds:',
      lightBackgroundTooltip: "The Lemontaps Logo will get replaced with your company's logo.",
      otherLightBackground: 'Upload another logo file for dark backgrounds:',
      logoLimit: 'only .png with transparent background, max size {{x}}MB',
      primaryButtonColor: 'Primary Button Color',
      sidebarColor: 'Navigation Sidebar Color',
      sidebarColorText: 'Navigation Sidebar Text Color',
      sidebarColorActive: 'Navigation Sidebar Active State Color',
      sidebarColorActiveText: 'Navigation Sidebar Active State Text Color',
      resetColors: 'Reset Colors',
    },
    visibility: {
      title: 'Visibility',
      description: 'You can hide here certain pages or boxes for both end users and admins',
    },
    unitSettings: {
      title: 'Unit Settings',
      multipleUnits: {
        title: 'Assign to Multiple Units',
        description:
          "Allow the assignment of one account to multiple units. One of the assigned units must be marked as the main unit. This unit's settings will determine the behavior of the account (profile design, edit rights, etc.). If this setting is turned off, each account may only be assigned to one unit.",
      },
    },
    setEditRights: 'Set Edit Rights',
    profileDesign: 'Profile Design',
    profileDesignSub:
      'Customize the design of the digital profile - decide about colors and images.',
    profileDesignInfo: {
      unitAdmin:
        'Customize the digital profiles in your unit according to your Corporate Design. The settings that you choose in this section will be applied to all digital profiles in your unit.',
      themeAdmin: {
        global:
          "Customize your digital profiles according to your Corporate Design. The settings that you choose in this section will be applied to all your digital profiles that don't have unit-specific settings.",
        unit: 'Customize your digital profiles in the selected unit according to their Corporate Design. The settings that you choose in this section will be applied to all digital profiles in that unit.',
      },
    },
    boxForm: 'Box-Form',
    rounded: 'Rounded',
    squared: 'Squared',
    buttonColors: 'Button-Colors',
    AdditionalImages: 'Additional Cover Images',
    strSaveContact: '“Save Contact”',
    genericWebsite: 'Generic Website Link',
    images: 'Images',
    Oops: 'Oops! Something went wrong!',
    anErrorHasOccured:
      'An unexpected error has occurred, we apologize for that. If the error still occurs, please contact our support team. You can go to the home page by clicking the "Go to Home" button. If you want to tell us something related to this error message, then click on "Contact us" to send a message to our support team.',
    goHome: 'Go to Home',
    message: 'Enter your message to our support team here',
    submitText: 'Submit',
    errorMessageSentSuccess: 'We received your message and will deal with it shortly',
    more: 'more',
    less: 'less',
    defaultCoverImage: 'Default-Cover Image',
    uploadCoverImage: 'Upload cover image',
    uploadFurtherCoverImage: 'Upload further cover image',
    uploadMoreImages: 'Upload title images that the employees must choose from',
    defaultAvatar: 'Default Profile Picture Avatar',
    logo: 'Logo',
    uploadLogo: 'Upload Logo',
    policies: '4. Policies',
    linkToImprint: 'Link to Imprint',
    linkToPrivacyPolicy: 'Link to privacy policy',
    nameOfYourCompany: 'Name of your company',
    addCopyRight: 'Add copyright?',
    allRightsReserved: '(© @year [your company name]. All rights reserved)',
    allRightsReservedPublic: 'All rights reserved',
    editRightsL: 'Edit Rights for employees',
    unitsGlobalSelect: 'Global (Default)',
    unitsSelectLabel: 'Select Unit',
    profilesSelectLabel: 'Choose Profile',
    editRights: {
      title: 'Edit Rights',
      mainHeading: 'Edit rights for employees',
      unitHeading: 'Edit rights for unit admins',
      mainTabLabel: 'For employees',
      unitTabLabel: 'For unit-admins',
      unitOverrideLabels: {
        profileDesign: {
          label: 'Profile Design',
        },
        editRights: {
          label: 'Edit Rights',
        },
        userDefinedTranslations: {
          label: 'Translations',
          subsettings: {
            heading: 'Translation Rights',
            subheading:
              'Grant Unit Admins access to certain translation files. They will have access to the whole file for the whole organisation. Every change they make, will affect the global file.',
            labels: {
              'profile--links': 'Links',
              'profile--files': 'Files',
              'profile--system-labels': 'System Labels',
              'profile--position': 'Positions',
              'profile--role': 'Roles',
              'profile--division': 'Divisions',
              'profile--bio': 'Bio Texts',
              'profile--addresses': 'Addresses',
              'profile--firstName': 'First Names',
              'profile--lastName': 'Last Names',
              'profile--company': 'Company Names',
              'profile--homepage': 'Homepage URLs',
              'profile--other-contact-info': 'Other Contact Info',
              'profile--urls': 'URLs',
            },
          },
        },
      },
      fields: {
        detailsEditable: 'Contact Information',
        profileImageEditable: 'Profile Picture',
        bannerImageEditable: 'Profile Banner',
        logoImageEditable: 'Header Logo',
        linksEditable: 'Links',
        filesEditable: 'Files',
        allowOwnQrBgImage: 'Individual QR-backgrounds',
        rebuyCards: 'Re-Ordering Cards',
        singleUserCrmPush: 'Allow users to push leads to CRM',
        profileShare_uidParam: 'Switch between profile designs',
      },
      tooltips: {
        allowOwnQrBgImage:
          'Determines whether your employees are allowed to upload their own images to create QR-backgrounds with.',
        rebuyCards:
          'Determines whether employees are displayed a link to our re-order form on the page "Share Profile" -> "NFC Card"',
        profileShare_uidParam:
          'If enabled, your employees will be able to switch between profile designs available to them. They will also be able to select the global default.',
      },
      fieldsSubHeadings: {
        detailsEditable:
          'Here you can define for each profile attribute whether it may be edited by your employees. In this way, you can prevent individual attributes from being edited by your employees while you allow the editing of other attributes.',
        linksEditable:
          'You can decide here which link variants may be created by your employees. As an admin you can still create all variants.',
      },
    },
    editRightsSub:
      'Decide on which information employees can change by themselves and which information only admins can change.',
    editRightsInfo: {
      unitAdmin:
        'Which information can employees change? Which information shall only be changed by the admins? The settings that you choose in this section will be applied to all users in your unit.',
      themeAdmin: {
        global:
          "Which information can employees change? Which information shall only be changed by the unit-admins or global admins? The settings that you choose in this section will be applied to all your digital profiles that don't have unit-specific settings",
        unit: 'Set specific edit rights for the selected unit.',
      },
    },
    editRightsBlockedFieldsTeaser:
      "Your current plan doesn't include the ability to set attribute-level edit rights.",
    blockedBioFieldsSubtext:
      'Here you can define for each profile attribute whether it may be edited by your employees. In this way, you can prevent individual attributes from being edited by your employees while you allow the editing of other attributes.',
    blockedLinkTypesSubtext:
      'You can decide here which link variants may be created by your employees. As an admin you can still create all variants.',
    profilePreview: 'Example Profile Preview:',
    comingSoon: 'Coming Soon',
    mdguide: {
      tasks: 'Tasks',
      pageHeader: 'Get started as an admin',
    },
    sharingDesign: {
      title: 'QR-Code Design',
      shortTitle: 'QR-Code Design',
      wallet: {
        tab: 'Wallet',
        title: 'Wallet Design',
        colors: {
          backgroundColor: {
            title: 'Background Color',
          },
          foregroundColor: {
            title: 'Text Color',
            tooltip: 'Only applied to Apple Wallet',
          },
          labelColor: {
            title: 'Label Color',
            tooltip: 'Only applied to Apple Wallet',
          },
        },
      },
      qrbg: {
        chooseImages: 'Choose Images',
        defaultImage: 'Default Image',
        additionalImages: 'Additional Images',
        mobile: {
          tab: 'Phone Background',
          title: 'Mobile background settings',
          helperText:
            'Any vertical format is allowed with a minimum width of 500px. We recommend the format of the most common phone among your team.',
        },
        videocall: {
          tab: 'Video Background',
          title: 'Video background settings',
          helperText:
            'Any horizontal format is allowed with a minimum width of 800px. We recommend the format for virtual backgrounds of the video call tool you use.',
        },
        dialogText: 'Are you sure you want to reset all images?\n\nThis is irreversible!',
        uploadSubtext: 'SVG, PNG, JPG or GIF (any format, minimum width: {{limit}}px)',
      },
    },
    accountGuides: {
      headerPage: 'Let’s start using your Lemontaps profile',
      subHeader:
        'On this page you’ll find an overview of guidelines to start using Lemontaps and getting the most out of it.',
      guidesHeader: 'Step-by-step descriptions to click through',
      videoGuides: {
        header: 'First Steps',
      },
      openQuestions: {
        header: 'Open questions?',
        takeALook: 'Take a look at the FAQ',
        takeALookLink: 'To our knowledge base',
        writeToSupport: 'Write to our support team.',
        writeToSupportLink: 'Create support ticket',
      },
    },
    mdAnalytics: {
      title: 'Analytics',
      profileSavings: 'The result are the following savings',
      networking: {
        title: 'Profile activity',
        subtitle: 'Take a look at how your team is using their cards.',
        shares: 'Profile Shares',
        vcards: 'vCards saved',
        contacts: 'Contacts Collected',
        edits: 'Profile Edits',
        links: 'Total Link Clicks',
        files: 'Total File Clicks',
      },
      tooltips: {
        shares: 'Amount of times your company profiles have been viewed.',
        contacts: 'Amount of contacts collected by all your company accounts.',
        vcards:
          'How many times the "Save Contact" button has been clicked on all your company profiles.',
        links: 'Total number of times some visitor opened a link across all your company profiles.',
        files: 'Total number of times some visitor opened a file across all your company profiles.',
        cards:
          "Number of paper business cards saved by sharing your company's profiles digitally or collecting a digital contact.",
        flyers:
          'Number of physical pages of paper saved by using a digital version instead of a paper printout (e.g., PDF files instead of paper flyers).',
        edits:
          'Every change to a profile of your team (contact information, images, files or links) counts as a profile edit. A maximum of one edit is counted per 7-day period.',
      },
      saves: {
        title: '🌱 Your company is a sustainable leader, you now saved:',
        cards: 'Paper Cards',
        flyers: 'Pages Of Flyers',
        subtitle: '... which means you saved:',
        wood: '🪵 Wood',
        water: '💧 Water',
        energy: '🔋 Energy',
        co2: '☁️ CO2',
      },
    },
    filesTab: {
      header: 'File templates',
      headerTooltip:
        'Define files here, that you can later choose from when adding files to your employees’ profiles.',
      fileName: 'Title',
      fileNamePlaceHolder: 'e.g. One-Pager',
      uploadImage: 'Upload Image',
      errorUploadingFile: 'Error when uploading file',
      successfullySaved: 'File successfully saved',
      successfullyDeleted: 'File successfully Deleted',
      writingDataError: 'Error while saving file',
      selectFileTitle: 'Select a file template',
      searchInputPlaceHolder: 'Search file templates',
      noTemplates: 'No file templates available',
      createTemplate: 'Create file template now',
      orAddIndividualFile: 'or add an individual file:',
      savingFile: 'Saving file',
      savingChanges: 'Saving changes',
      deletingFile: 'Deleting file',
      deleteFile: 'Delete File',
      yesDelete: 'Yes, delete',
      confirmDelete: 'Are you sure you want to delete this file?',
      uploadFile: 'Upload File',
    },
    addressesTab: {
      header: 'Default addresses',
      headerTooltip:
        'Define addresses here, that you can later choose from when adding addresses to your employees’ profiles.',
      addAddresses: 'Add Address',
      addNewAddress: 'Add a new address',
      name: 'Name',
      address: 'Street and House Nr',
      postCode: 'Zip code',
      makeAddressDefault: 'Make this the default address?',
      addressDefaulTooltip:
        'Newly added profiles will have this address assigned to them by default.',
      default: 'Default',
      confirmDelete: 'Are you sure you want to delete address?',
      ErrorDeletingAddress: 'Error while deleting address',
      cannotDeleteDefaultAddress: 'You cannot delete default address.',
      errorReadAddress: 'Unable to load addresses',
      errorWriteAddress: 'At least one address field required!',
      errorWriteLabel: 'The address must have a name!',
      saveSuccess: 'Successfully saved',
      noAddresses: 'No addresses added yet',
      emptyAddressFieldsError: 'Please fill in at least one field',
    },
    qrBgTab: {
      defaultBackgrounds: 'Default Backgrounds',
      defaultMobileBg: 'Default image for QR mobile lock screen:',
      defaultVideoCallBg: 'Default image for QR video call background:',
      minBgSize: 'Image width: at least {{x}}px',
      uploadBg: 'Upload image',
      additionalBgImgs: 'Additional Background Images',
      additionalBgImgsDescription: 'Upload further images that users can choose from',
      additionalMobileBgs: 'More mobile wallpapers:',
      additionalVideoCallBgs: 'More videocall wallpapers:',
    },

    linkTab: {
      header: 'Link Templates',
      headerTooltip:
        'Define links here, that you can later choose from when adding links to your employees’ profiles.',
      addLink: 'Add ',
      addNewLink: 'Add a new Link',
      name: 'Title',
      linkType: 'Link Type',
      linkName: 'Link Name',
      linkUrl: 'URL',
      linkPhone: 'Phone number',
      placeHolderUrl: 'e.g. www.website.com',
      placeHolderLink: 'e.g. Our Website',
      placeHolderPhone: 'e.g. 49 170 12345',
      searchStandardLinks: 'Search link templates',
      noTemplates: 'No link templates available',
      createTemplate: 'Create link template now',
      deleteLink: 'Delete Link',
      confirmDelete: 'Are you sure you want to delete link?',
      yesDelete: 'Yes, delete',
      successAdd: 'Added',
      successEdit: 'Updated',
      errorAdd: 'Error',
      errorEdit: 'Error',
      successDelete: 'Deleted',
      errorDelete: 'Error',
      orAddIndividualLink: 'or add an individual link:',
    },
    rename: 'Rename',
    locationSelector: {
      header: 'Select from standard addresses',
      max2Label: '(a maximum of 2 addresses can be added)',
      orCreateIndividual: 'or create an individual address',
      searchAddress: 'Search address',
      noTemplates: 'No address template available',
      createTemplate: 'Create address template now',
    },
    employeeType: {
      role: 'Role in company',
      employee: 'Employee',
      themeAdmin: 'Administrator',
      unitAdmin: 'Unit-Admin',
      edit: 'edit',
      sendInviteText:
        'When saving, automatically send an invite email to the new address? You can do this later manually as well.',
      profile: 'Profile',
      account: 'Account',
    },

    errorSharingData: 'Error sharing data',
    errorLoad: 'Error while loading contacts',
    errorLoadMore: 'Error while loading more contacts',
    downloading: 'Downloading',
    successfullyDownloaded: 'Successfully downloaded',
    errorWhenDownloading: 'Error while downloading',
    // MD Customization strings end

    //How To Tap Strings Start//
    HowToTap: 'How to tap',
    HowToTapDescription: 'Choose the device you are sharing to',
    important: 'Important!',
    BackupDescriptionHeading: 'You always have a Backup',

    TapNewIphoneHeading: 'Tap to new iPhones',
    TapNewIphoneSubHeading: 'iPhone XR and newer',
    TapNewIphoneDetailHeading: 'Tap to Newer iPhones',
    TapNewIphoneDetailSubHeading: 'iPhone XR, XS, 11, 12, 13',
    TapNewIphoneDetailDescription:
      'To share to newer iPhones, slide and hold your Lemontaps card near the very top of the iPhone until a push notification appears.',
    TapNewIphoneDetailImportantDescription:
      'Their screen must be on, their airplane mode must be off, and their camera must not be open.',
    TapNewIphoneDetailBackupDescription:
      'The tapping requires some training and eventually it will happen that it doesn’t tap right away. Don’t worry, because you can always use the QR-Code.',

    TapOlderIphoneHeading: 'Tap to older iPhones',
    TapOlderIphoneSubHeading: 'iPhone X and older',
    TapOlderIphoneDetailHeading: 'Tap to older iPhones',
    TapOlderIphoneDetailSubHeading: 'iPhone 6, 7, 8, X',
    TapOlderIphoneDetailDescription:
      'To share to older iPhones, the receiver needs to press on the NFC widget in their control center. Then slide and hold your Lemontaps card near the very top of the iPhone.',
    TapOlderIphoneDetailImportantDescription:
      'Sometimes the NFC widget has to be activated in the settings to appear in the control center. Furthermore their screen must be on, their airplane mode must be off, and their camera must not be open.',
    TapOlderIphoneDetailBackupDescription:
      'The tapping requires some training and eventually it will happen that it doesn’t tap right away. Don’t worry, because you can always use the QR-Code.',

    TapAndroidHeading: 'Tap to Androids',
    TapAndroidSubHeading: 'Must have NFC on',
    TapAndroidDetailHeading: 'Tap to Androids',
    TapAndroidDetailSubHeading: 'Every Android with NFC (almost all)',
    TapAndroidDetailDescription:
      'To share to Androids, slide your Lemontaps card around the center back area of their phone. Every Android has a slightly different spot for their NFC reader.',
    TapAndroidDetailImportantDescription:
      'Ask if their NFC is turned on. To turn on the NFC pull down the Quick Settings Menu and press on the NFC button.',
    TapAndroidDetailImportantDescription2:
      'Sometimes you need to search ‘NFC’ in the phone settings. ',
    TapAndroidDetailBackupDescription:
      'The tapping requires some training and eventually it will happen that it doesn’t tap right away. Don’t worry, because you can always use the QR-Code.',

    TapScanHeading: 'Scan QR-Code',
    TapScanSubHeading: 'All Phones',
    TapScanDetailHeading: 'Scan QR-Code',
    TapScanDetailSubHeading: 'All iPhones and Android Phones',
    TapScanDetailDescription:
      'You can find your QR-Code in the Lemontaps Home Dashboard or printed on your card. Most devices can simply scan the QR-Code with their normal camera.',
    TapScanDetailImportantDescription:
      'You can create your phone lockscreen and videocall background with your QR-Code to share your Lemontaps business card even faster.',

    // How to Tap Strings End //
    contactShareFile: '_Profile',
    contactShareTitle: 'Contact ',
    contactShareText: 'Digital Contact from ',
    leaveYourContact: 'Leave me your contact',
    submit: 'Submit',
    profileForwardNotice: 'Note: Your profile is currently forwarded to {{url}}',
    downloadContactFile: 'Download Contact File',
    md: {
      requestNow: 'Request now',
      requestNowBullets: ['No credit card or cancellation necessary.', 'No installation required.'],
      profiles: {
        sidebar: {
          tabs: {
            tooltipProfile: 'Edit profile information such as name, phone numbers, addresses, etc.',
            tooltipAccount: 'Edit the login email and role',
            tooltipCards: 'Add or remove cards to/from this profile',
          },
        },
        error: {
          deleteMainAccount: 'You cannot delete the team owner.',
        },
      },
      vcardConfigurator: {
        title: 'vCard Configurator',
        description:
          'The vCard is the contact file that is downloaded when clicking on “Save contact” or when sharing the offline QR-Code. You can edit some of the settings here.',
        contactInfoTitle: 'Contact Info Configuration',
        contactInfoDescription:
          'Decide whether to hide certain fields in the vcard or to change the label of the attribute. Keep in mind that on some phones (especially Androids) the custom label will not be shown. They are instead replaced with default labels such as “Other”.',
        chooseLabel: 'Choose Label',
        createLabel: 'Create Label',
        editLabel: 'Edit Label',
        createCustomLabel: 'Create a custom label',
        customLabelGerman: 'Label (German)',
        customLabelEnglish: 'Label (English)',
        customLabelInfo: 'Type any custom label or choose from the presets.',
        notes: 'Notes',
        notesGerman: 'Notes (German)',
        notesEnglish: 'Notes (English)',
        notesTooltip:
          'Configure a custom text that will be included in the "notes" section of the downloaded contact. You can use "{{currentDate}} as a variable which will be replaced with the current date when the vcard is downloaded.',
        additionalSettings: 'Additional Settings',
        showLinks: 'Show all profile links',
        showLinksTooltip: 'Include profile links in the downloaded contact.',
        showLinksOffline: 'Also show all profile links in offline QR-Code',
        showLinksOfflineTooltip:
          'Only recommended when you have a small amount of links. Otherwise, the QR-Code will be difficult to read.',
        convertSpecialChars: 'Convert special characters in vCard',
        convertSpecialCharsTooltip:
          'Replace special characters like ä, ö, ü with ae, oe, ue, since some Outlook versions may not be able to interpret those.',
        samelLabelOutlookWarning:
          'To achieve maximum compatibility, the same attributes should have different names, as this can lead to incorrect imports, particularly with Outlook.',
        sameLabelEmailWarning:
          '{{fields}} have the same label or both have custom labels. The import into outlook might fail!',
        sameLabelAddressWarning:
          '{{fields}} have the same label or both have custom labels. When importing the vCard into Outlook, only one of the addresses might be displayed!',
        sameLabelLinkWarning:
          '{{fields}} have the same label or both have custom labels. When importing the vCard into Outlook, only one of the links might be displayed!',
        show: 'Show "{{field}}" field in vcard',
        doNotShow: 'Do not show "{{field}}" field in vcard',
      },
      defaultContent: {
        defaultLink: 'Default Link',
        defaultLinks: 'Default Links',
        defaultFil: 'Default File',
        defaultFiles: 'Default Files',
        description:
          'You can add default addresses, links and files that are going to be shown on every profile. When information changes, just update the content here and the update will be applied on all profiles',
        errorLoadingFiles: 'Error loading default files',
        successSaveFile: 'File saved successfully',
        errorSavingFile: 'Error saving file',
        successDeleteFile: 'File deleted successfully',
        errorDeleteFile: 'Error deleting file',
        errorLoadingLinks: 'Error loading default links',
        successSaveLink: 'Link saved successfully',
        errorSavingLink: 'Error saving link',
        successDeleteLink: 'Link deleted successfully',
        errorDeleteLink: 'Error deleting link',

        addAddress: 'Add default address',
        editAddress: 'Edit default address',
        defaultAddresses: 'Default Addresses',
        errorLoadingAddress: 'Error loading address',
        successSaveAddress: 'Address saved successfully',
        errorSavingAddress: 'Error saving address',
        successDeleteAddress: 'Address deleted successfully',
        errorDeleteAddress: 'Error deleting address',
      },
      contentTemplate: {
        linkTemplates: 'Link Templates',
        fileTemplates: 'File Templates',
        addressTemplates: 'Address Templates',
        description:
          'Create Content Templates for Addresses, Links and Files that you as an admin can choose from when editing profiles. When you update the template all profiles that are using that template will have that update. ',

        errorLoadingFiles: 'Error loading file templates',
        successSaveFile: 'File template saved successfully',
        errorSavingFile: 'Error saving file template',
        successDeleteFile: 'File template deleted successfully',
        errorDeleteFile: 'Error deleting file template',

        errorLoadingLink: 'Error loading link templates',
        successSaveLink: 'Link template saved successfully',
        errorSavingLink: 'Error saving link template',
        successDeleteLink: 'Link template deleted successfully',
        errorDeleteLink: 'Error deleting link template',

        errorLoadingAddress: 'Error loading address templates',
        successSaveAddress: 'Address template saved successfully',
        errorSavingAddress: 'Error saving address template',
        successDeleteAddress: 'Address template deleted successfully',
        errorDeleteAddress: 'Error deleting address template',
      },
      profileDesign: {
        previewTooltip: 'Save your changes first and then click on preview',
        freeToChoose: 'Current setting: Employees can freely choose their banner images',
        boxFormTooltip: 'Determines the appearance of all box-shaped elements in the profiles.',
        uploadImageTooltip:
          'If no images are uploaded, employees are free to choose their cover image. When uploading one or more images, your employees will see a selection of these images, from which they must choose one. In the latter case no images can be uploaded freely.',
        buttonColorSaveContactTooltip:
          'Background color of the "Save Contact" and the "Submit" button of the contact submission form.',
        buttonColorFilesTooltip: 'Background color of the file buttons.',
        buttonColorGenericWebsitePicker: 'Background color of the generic website buttons.',
        coverImageTooltip:
          'Determines the default cover image when no individual cover image is uploaded for the user. You can also decide not to show any cover image. When updating the default cover image only the profiles without an individual cover image will be updated.',
        profileImageTooltip:
          'Determines the default avatar when no individual profile image is uploaded for the user. You can also decide not to show any avatar. When updating the avatar only the profiles without an individual profile image will be updated.',
        logoImageTooltip:
          'While empty, users can upload their own custom image as a logo to their profile. When this option is set, then users will not be able to upload their own image as a logo.',
        showForm:
          'If you disable this feature, the contact form will no longer be displayed in your digital business card profile.',
        showFormAsPopup:
          'If you enable this feature, then upon opening your digital business card profile, the contact form will be displayed directly in the form of a popup. This feature is useful at events to quickly and easily capture the contact information of the other person.',
        themeLinks: {
          tooltip: `The default links will be displayed on every employees' profile. They are not able to edit or delete them.`,
          title: 'Position Default Links',
        },
        themeLinksPosition: {
          before: 'Before individual links',
          after: 'After individual links',
          question: 'Where to display the default links?',
        },
        themeFiles: {
          tooltip: `The default files will be displayed on every employees' profile. They are not able to edit or delete them.`,
          title: 'Position Default Files',
        },
        themeFilesPosition: {
          before: 'Before individual files',
          after: 'After individual files',
          question: 'Where to display the default files?',
        },
        themeAddresses: {
          tooltip: `The default addresses will be displayed on every employees' profile. They are not able to edit or delete them.`,
          title: 'Position Default Addresses',
        },
        themeAddressesPosition: {
          before: 'Before individual addresses',
          after: 'After individual addresses',
          question: 'Where to display the default addresses?',
        },
        linksTooltip: 'You can set up link templates via settings -> global company data',
        filesTooltip: 'You can set up file templates via settings -> global company data',
        additionalLogosTitle: 'Additional Logos',
        additionalLogosTooltip:
          'If no images are uploaded, employees are free to choose their logo. When uploading one or more images, your employees will see a selection of these images, from which they must choose one. In the latter case no images can be uploaded freely.',
        additionalLogosDescription: 'Upload logos that the employees must choose from',
        additionalLogosUploadButton: 'Upload Logo',
        additionalLogosUploadFurtherButton: 'Upload further Logo',
        additionalLogosCurrentSettings: 'Current setting: Employees can freely choose their logo',
        additionalLogosDefaultLogoIsSet:
          'Current Setting: A global logo is defined that is displayed on every profile. Remove the default logo set above so you can upload multiple logos here.',
      },
      customizationFooter: {
        mainTooltip:
          'The information saved here will be displayed in the footer of the public profile.',
      },
    },
    preview: 'Preview',
    home: 'Home',
    profile: 'Profile',
    contacts: 'Contacts',
    mdCards: {
      title: 'Cards',
      upgradeToContinue:
        'Unfortunately, you cannot select this action during the trial period. Please upgrade to continue.',
      noBoxThemeAdmin: 'Contact us to enable options to order NFC-cards from this page.',
      noBoxUnitAdmin:
        'Your administrator has disabled the option to reorder nfc-cards from this page.',
      order: {
        title: 'Order NFC-Cards',
        imageAlt: 'Depiction of physical NFC-Cards',
        body: 'Order free sample cards , reorder cards with your last design or order cards with a new design.',
        actionLabel: 'Order NFC-Cards',
        subaction: {
          sampleCards: {
            label: 'Order Sample Cards',
            tooltip:
              'They are free to order and come with a sample design. The cards have to be sent back after testing.',
          },
          firstTimeOrder: {
            label: 'Order Cards with new design',
            tooltip: 'If you design should change or you order cards for the first time.',
          },
          reorder: {
            label: 'Reorder Cards with same design',
            tooltip:
              'If you have already ordered cards before, we have your design ready and all you need to do is to fill out an order form.',
          },
        },
        byCountryPopup: {
          title: 'Delivery Country',
          other: 'Other country? Please contact support@lemontaps.com',
        },
      },
      paperCardsBox: {
        title: 'Paper Business Card Ordering System',
        description:
          'Manage the administrative settings of the paper business card ordering system, such as approval processes. You can also order new paper business cards for your employees.',
        imageAlt: 'A paper business card',
        buttonText: 'Manage Paper Business Cards',
        actions: {
          approval: 'Go to Release System',
          order: 'Place order for employees',
        },
      },
    },
    nfcSample: {
      title: 'Order Sample Cards',
      subtitle:
        'Try out our NFC cards. You can order the sample cards for free if you return them.',
    },
    nfcFirstOrder: {
      title: 'Order cards for the first time',
      subtitle:
        'As a Teams user, you have access to discounted card prices. Design the cards to match your corporate design.',
    },
    nfcReorder: {
      title: 'Reorder cards with last design',
      subtitle:
        'Here you have the option to easily reorder more cards with the same design as your last order.',
    },
    settings: {
      main: 'Account Settings',
      more: 'More',
      managementPlatform: 'Management Platform',
      changePassword: 'Change Password',
      changeEmail: 'Change login email',
      help: 'Help & Support',
      helpCaption: 'Help Center, Academy, Support',
      openCookieSettings: 'Change Cookie Settings',
      openShopSettings: 'Go to our shop',
      openPaperCardForm: 'Order paper business card',
      mycards: 'Manage cards',
      changeLanguage: 'Change Account Language',
      selectLanguage: 'Select Language',
      paperCardOrder: 'Print version',
      paperCardOrderCaption: 'Order paper business cards',
    },
    moreSettings: {
      title: 'More',
      teamMangement: 'Team Management',
      teamMangementCaption: "Manage your team's business cards",
      analytics: 'Analytics',
      analyticsCaption: 'Profile & sustainability insights',
      addApp: 'Add App',
      addAppCaption: 'Add the application to your phone',
      accountSettings: 'Account Settings',
      accountSettingsCaption: 'Change email, password ...',
      nfcCards: 'NFC-Cards',
      nfcCardsCaption: 'Activate, manage and order',
      printCard: 'Print Version',
      printCardCaption: 'Order new paper business cards',
      signOut: 'Sign Out',
    },
    changeEmail: {
      title: 'Change login email',
      currentEmail: 'Current email address',
      newEmail: 'New email address',
      notAllowed: {
        title: `Email cannot be changed`,
        text: `You are authenticated via {{idp}}, therefore you cannot change your email here. If you need another email, please delete your account and sign up again or contact an administrator.`,
      },
      error: {
        invalidFormat: 'Enter a valid email',
        unchanged: 'Enter a new email',
      },
    },
    homePage: {
      toAcademy: 'To Academy',
      hello: 'Hello',
      digitalBusinessCard: 'Your digital business card',
      shareProfile: {
        title: 'Share Profile',
        copyToShare: 'Copy to share',
        actionTitle: 'View all sharing options',
        via: 'via Link, QR-Code, Wallet...',
      },
      addAppToPhone: {
        title: 'Add homescreen app',
        popupTitle: 'Add Lemontaps as a homescreen app',
        step1: `Open page on your phone`,
        step2: 'Click on the “Share” or “More” Icon of your browser',
        step3: 'Click on “Add to Homescreen',
      },
      nfcCard: {
        addAndManage: 'NFC-Cards',
        title: 'NFC Card',
        allAboutNFC: 'Everything about the NFC card',
        manageCards: 'Manage Cards',
        manageCardsSub: 'Link new cards or remove them.',
        instruction: 'Instruction',
        instructionSub: 'Learn the best practices on how to "tap" the card with different phones.',
        buyNew: 'Buy New Cards',
        buyNewSub: 'Customize the card with your design. Available in bamboo, metal and plastic.',
        multiTitle: 'Discover our NFC cards',
        multiText:
          'Customize the cards with your design. Available in bamboo, recycled plastic and metal.',
        btnText: 'To the shop',
      },
      leads: {
        recentContacts: 'Recent Contacts',
        title: 'Capture leads',
        manage: 'To the contact management',
        previewForm: 'View lead form on my profile',
      },
      analytics: {
        title: 'Analytics',
        profileViews: 'Total Profile Views',
        leadsCollected: 'Total Leads Collected',
      },
      academy: {
        title: 'First steps & Academy',
        popupTitle: 'Lemontaps Academy is not available in your current plan',
        popupText:
          'Lemontaps Academy is a collection of introductory and best practice videos, such as how to use Lemontaps at fairs or in 1 on 1 meetings.\nThis feature is an Addon and not available in your current plan.',
      },
      support: {
        title: 'Your contact person',
        admin: 'Your Admin',
        lemontapsSupport: 'Lemontaps Support Team',
      },
      quickActions: {
        title: 'More Quick Actions',
        addApp: 'Add App',
        nfcCards: 'NFC Cards',
        analytics: 'Analytics',
        scanCard: 'Scan Card',
      },
      viewAll: 'View all',
      learnMore: 'Learn more',
      ////not translated DE
      addLemontapsAsAnApp: 'Add Lemontaps as an homescreen app',
      addAppToHomescreen:
        'Add it as an app to your homescreen. This way you can always easily access your profile and account.',
      exampleForIphone: 'Example for Iphone (similar to Android):',
      gotIt: 'Got it!',
      ////
      shareProfilePer: 'Share profile via...',
      addQrWallpaper: 'Create QR-Wallpaper',
      qrCodeWallpaper: 'QR-Code Background',
      subTitle: 'Explore the different options on how your can use your Lemontaps profile: ',
      qrTitle: 'QR-Wallpaper ',
      qrText: 'Link your Lemontaps Profile to your phone and videocall background.',
      qrBtnText: 'Create Background ',
      shareLink: 'Share Link',
      shareYourLink: 'Share your link',
      tip: '💡Tip:',
      tipText: 'Copy and paste the link into your email signature:',
      qrCode: 'QR-Code',
      profileViews: 'Analytics',
      overviewProfileViews: 'Profile views overview',
      total: 'Total: ',
      thisWeek: 'This week:',
      howToTap: 'How to Tap?',
      help: 'Help',
      desktopSubTxt: 'Discover different ways you can use your Lemontaps profile:',
      copyLink: 'Copy profile link',
    },
    leadGen: {
      upgradeTitle: 'Upgrade Now!',
      upgradeText: 'Upgrade Now to be able to see all your collected contacts!',
      upgradeBtnText: 'Get the Upgrade',
      upgradeBtnClickedText: 'Request sent',
      noLeads: 'No contacts collected so far.',
      infoFromProfile: 'Information from Profile',
      infoFromContact: 'Information from Contact',
      lt2ltNotFound: 'Oops! Profile not found 🙈',
      uploadNew: 'Upload new',
      businessCardTitle: 'Physical Business Card',
      businessCardSubtitle: 'Upload an image of a paper business card',
    },
    showQr: {
      pageTitle: 'QR-Code',
      online: 'online',
      offline: 'offline',
      btnTxt: 'Download QR-Code',
      cardHeadTxt: 'Use Case: ',
      cardDiscTxtOn: 'Use your QR-Code in presentations.',
      cardDiscTxtOf:
        'The offline QR-Code works without internet, but only opens the vCard and not profile.',
      downloadSuccess: 'Download was successful',
      downloadError: 'Please reload the page and try again',
      downloadQrBgsError: 'You first have to set a default image in the settings',
      downloadQrBgsErrorMaxAllowed: 'Only {{max}} profiles can be selected for this action.',
    },
    datePicker: {
      options: {
        title: 'Select time range',
        today: 'Today',
        pastWeek: 'Past week',
        pastMonth: 'Past month',
        past90Days: 'Past 90 days',
        pastYear: 'Past year',
        allTime: 'All time',
      },
      heading: {
        startDate: 'Start Date',
        endDate: 'End Date',
      },
    },
    analytics: {
      options: {
        title: 'Select time range',
        today: 'Today',
        pastWeek: 'Past week',
        pastMonth: 'Past month',
        past90Days: 'Past 90 days',
        pastYear: 'Past year',
        allTime: 'All time',
      },
      saves: {
        title: '🌱 You are a sustainable leader, you now saved:',
        cards: 'Paper Cards',
        flyers: 'Pages Of Flyers',
        subtitle: '... which means you saved:',
        wood: '🪵 Wood',
        water: '💧 Water',
        energy: '🔋 Energy',
        co2: '☁️ CO2',
      },
      networking: {
        title: '{{firstName}}, you are a real networker!',
        titleSmall: 'Your networking stats',
        shares: 'Profile Shares',
        contacts: 'Leads Collected',
        vcards: 'vCard Saved',
        edits: 'Profile Edits',
      },
      clicks: {
        unit_one: '{{count}} Click',
        unit_other: '{{count}} Clicks',
        title: 'Let’s look at the details:',
        links: 'Total Link Clicks',
        files: 'Total File Clicks',
        oldFileClicks: 'Clicks on old Files',
        oldLinkClicks: 'Clicks on old Links',
      },
      tooltips: {
        vcards: 'The "Save contact" button on your profile page has been clicked this many times.',
        edits:
          'Everything you change in your profile (links, files, contact info, etc.) is a profile edit. Per 7-day period a maximum of one edit is counted.',
        contacts: 'You have collected this many contacts.',
        shares: 'You have shared your profile this many times.',
        cards:
          'Number of paper business cards saved by sharing your profile digitally or collecting a digital contact.',
        flyers:
          'Number of physical pages of paper saved by using a digital version instead of a paper printout (e.g., PDF files instead of paper flyers).',
      },
      share: {
        share: 'Share',
        dialogTitle: 'Share your analytics',
        title: 'My sustainable networking stats',
        text: 'My impact using the digital and sustainable business card by Lemontaps:\n\n🎫   I’ve saved {{cards}} paper cards\n📃   I’ve saved {{flyers}} pages of flyers \n🪵   I’ve saved {{wood}}kg wood \n💧   I’ve saved {{water}}l water \n🔌   I’ve saved {{energy}}kWh energy \n🌫️   I’ve saved {{co2}}kg CO2 \n\nJoin the networking revolution and be a digital and sustainable leader! Register now with Lemontaps for free yourself!',
      },
    },
    loginOrRegister: {
      title: 'This card is not connected to any business card profile yet.',
      connectNew: 'Create new profile',
      connectExisting: 'Connect card to existing profile',
      linkAsAdmin: 'Link card as administrator',
    },
    linkCode: {
      noProfilesFound: 'No profiles found',
      searchBy: 'Search by',
      selectProfile: 'Select a profile to connect',
      searchProfile: 'Search profile',
      close: 'Close',
      editDate: 'Last edit date',
      createDate: 'Creation date',
      sortBy: 'Sort by',
      sortOrder: 'Sort order',
      asc: 'Ascending',
      desc: 'Descending',
      filter: 'Filter',
      withoutNFC: 'Without a NFC card connected',
      successLinkTitle:
        'Congratulations, you have successfully connected the selected profile to the NFC-Card.',
      successLinkSubTitle: 'Now open your camera to scan the next NFC-Card.',
      errorLinkTitle: 'An error occurred',
      tryAgain: 'Try again',
      dontShowAgain: 'Don’t show again',
      confirmLinkText: 'Are you sure you want to connect the selected profile to the NFC-Card?',
      connectToNfc: 'Connect to NFC-Card?',
    },
    onboarding: {
      howShareProfile: 'That’s how you share your profile',
      howMany: 'How many employees are in your company?',
      howManyPotentialUsers: 'Of those, how many could be using Lemontaps?',
      whyLemontaps: {
        title: 'What is your goal using Lemontaps?',
        personal: 'For personal use',
        company: 'Testing for my company/team',
      },
      viaQR: 'via QR-Code',
      viaNFC: '... or tap via NFC',
      slideVeryTop: 'Slide near the very top:',
      mustPressNFCbutton: 'The receiver must first press the NFC button in the control center:',
      androidGifText1: "Ask if the receiver's NFC is turned on",
      androidGifText2: 'Slide the back of the phone back and forth ',
      turnOnNFC: 'How to turn on NFC',
      lastAdvice: 'Some last advice',
      congratsTitle: 'Congrats! You are ready to start networking now 🎉',
      importForNFC: 'Important for NFC:',
      lastAdviceAboutNFC: {
        pointOne: 'screen must be on',
        pointTwo: 'airplance mode must be off',
        pointThree: 'camere must not be open',
      },
      advice:
        'The Tapping via NFC takes a little practice, but don’t worry if it doesn’t work - you can always let them scan the QR-Code 🤗',
      continue: 'Continue',

      welcome: `Welcome - lets' create your digital business card`,
      addToWallet: {
        title: 'Add QR-Code to your wallet',
        description:
          'To share your digital business card, add the QR-Code to your wallet. We have also sent you your wallet via e-mail.',
        step1: 'Choose your QR-Code type',
      },
      tellUs: 'Tell us about yourself',
      forPersonalize: 'For a personalized Lemontaps experience',

      other: 'Other',
      selectCategory: 'Select a category that best describes you',
      enterOtherCategory: 'Enter other Category',
      howDidYouhear: 'How did you hear about Lemontaps',
      EnterOther: 'Enter other',
      letsStart: `Let's start`,
    },
    'sales-manager': 'Sales Manager',
    'field-representative': 'Field Representative',
    hr: 'HR',
    procurement: 'Procurement',
    consultant: 'Consultant',
    'c-level': 'C-Level',
    it: 'IT',
    freelancer: 'Freelancer',
    marketing: 'Marketing',
    other: 'Other',
    openCookieSettings: 'Cookie Settings',
    openShopSettings: 'Go to our shop',
    showMore: 'Show more',
    showLess: 'Show less',
    optional: 'optional',
    publicProfile: {
      autoConnect: {
        heading: 'Contact created',
        subheading: 'Add some notes.',
        button: 'View',
      },
      actionsBar: {
        buttonLabel: 'Back To Platform',
      },
      profileForced:
        'When shared, your profile currently forwards to "{{url}}". If you do not want to forward your profile any more, you need to deactivate the flash on the respective link.',
    },

    shareTo: {
      shareDestination: 'Share to..',
      shareDetails: 'Share your Lemontaps to all your connections so everyone knows about it.',
      shareWhatsapp: 'Share via Whatsapp',
      shareLinkedin: 'Share via Linkedin',
      shareEmail: 'Share via Email',
      shareFacebook: 'Share via Facebook',
      shareTelegram: 'Share via Telegram',
      shareMail: 'Share via Mail',
      shareViaOther: 'Share via other',
      copyAndShare: 'Copy text and share via {{via}}',
      textCopied: 'Text copied successfully!',
      shareProfile: {
        shareYourProfile: 'Share your porfile',
        shareYourProfileDetails:
          'Network and connect better by sharing your Lemontaps profile everywhere. ',
        userText: 'Use lemontaps in',
        qrCode: 'My Lemontaps QR-Code',
        lemonTapsShare: 'Share my Lemontaps to ..',
      },
      presentations: {
        useInPresentations: 'Use in Presentations',
        useInPresentationsDetails:
          'Place your Lemontaps QR-Code in presentations so everyone can connect with you easily.',
      },
      emailSignature: {
        eMailSignature: 'E-Mail Signature',
        eMailSignatureDetails: 'Copy and paste your Lemontaps link into your email signature:',
      },
      useInVideoCalls: {
        useInVideoCalls: 'Use in Videocalls',
        useInVideoCallsDetails:
          'Generate your videocall background with your Lemontaps QR-Code so that you also have a business card at online meetings.',
      },
      useIn: {
        useInPersonalMeetings: 'Use in Personal Meetings',
        useInVideoCalls: 'Use in Videocalls',
        useInEmail: 'Use in E-Mail Signature',
        useInPresentations: 'Use in Presentations',
      },
      createBackground: 'Create Background',
    },

    fieldRequired: 'Field required',
    cards: {
      card: 'Karte "{{code}}"',
      connect: 'Connect',
      alert: {
        title: 'Flexible card mangement',
        subtitle:
          'You can connect to and remove cards from your profile at any time. Removed cards can be connected to new profiles.',
      },
      orderCard: 'Order',
      reorderCard: 'Re-Order',
      connectCard: 'Connect Card',
      deletePopup: {
        title: 'Remove Card?',
        description: 'Once the card is removed it can be connected to any profile again.',
      },
      enterCodePopup: {
        title: 'Enter code to connect',
        description:
          'Note: This is only a backup method if the camera doesn’t work. To receive the code please contact support@lemontaps.com.',
      },
      scanCardPopup: {
        title: 'Scan the QR-code on your card',
        description:
          'QR-Code is not recognized??\n\n1. Scan the QR-Code using your normal phone camera app\n2. Backup: Contact support@lemontaps.com and then <0>enter the code manually</0>',
      },
      noCardsViewWithShop:
        'No cards are connected to your profile yet. Connect or order a card by clicking below.',
      noCardsViewWithoutShop:
        'No cards are connected to your profile yet. Connect your first card now.',
      cardsHeader: 'Overview of connected cards',
      loadingCards: 'Loading cards',
      noCards: 'No cards',
      connectANewCard: ' Connect a new card',
      cards: 'Cards',
      remove: 'remove',
      uploadingCode: 'Validating code',
      successUpload: 'Code connected',
      errorUpload:
        'Code could not be connected. Check the values again. The code could already be in use as well.',
      successDelete: 'Card removed',
      errorDelete: 'Card could not be removed',
      codeDelete: 'Remove card',
      areYouSureDelete: 'Do you really want to remove this card?',
      headToolTip:
        'Any number of cards can be connected to a profile. When a card is removed, the profile is no longer on the card and the card can be linked to a new profile.',
      bottomToolTip:
        'As an admin you can link the card by scanning the QR code. You can also distribute the card to the employees, they can log in to link the card to their profile.',
      cardAlreadyConnected: 'This card is already connected to this account',
      cardFrom: 'Card from',
      nodate: 'The date is not available for cards that were connected prior to 10/20/2022.',
      notValid: 'Code is not valid',
    },
    md2Phone: {
      popupStep1: 'Step 1: Scan the following QR code with your mobile phone',
      popupStep2:
        'Step 2: Now follow the instructions on the mobile phone and scan the QR code of the card. The QR-Code is valid for 24 hours.',
      title: 'Scan the QR code of the card',
      subTitle:
        'The card is then automatically linked to the profile from the Management Dashboard.',
      success:
        'Card was successfully linked to the profile.\n Please close the screen and go back to the Management Platform and refresh the page to see the changes or newly connected card!',
      noCodeToken: 'No code or token found, please close the page and try again.',
    },
    csvImport: {
      label: 'Bulk Actions',
      add: 'Add Profiles',
      update: 'Update Profiles',
      delete: 'Delete Profiles',
      toast: {
        added: 'Profiles added successfully',
        backgroundImport:
          'You will receive an e-mail as soon as the profiles have been created. This may take a little while.',
        otherCards: 'Order new cards',
        selectedCards: 'You have selected to order @amount card(s) during the .csv import.',
        cardsMessage:
          'These will be produced in the same design as your last order. You will receive an invoice of all newly ordered cards and licenses once per quarter.',
        addedThenError: 'Profiles added successfully, but the re-loading failed',
        updated: 'Profiles updated successfully',
        updatedThenError: 'Profiles updated successfully, but the re-loading failed',
        deleted: 'Profiles deleted successfully',
        deletedThenError: 'Profiles deleted successfully, but the re-loading failed',
        exceedtotalLicensing0: 'License quota exceeded',
        exceedtotalLicensing1: `If you add more profiles, you will exceed the number of your paid licenses.
          You will receive an invoice by email at the end of the month for the newly added licenses. You will be charged the license fees only proportionally to the remaining contract period.

          Do you still want to continue?`,
        exceedtotalLicensing2: 'Available licences:',
        exceedtotalLicensing3: `You have selected to order @amount card(s) during the .csv import.
        These will be produced in the same design as your last order. You will receive an invoice of all newly ordered cards and licenses once per quarter.

        If you add more profiles, you will exceed the number of your paid licenses.

You will receive an invoice by email at the end of the month for the newly added licenses. You will be charged the license fees only proportionally to the remaining contract period.

                  Do you still want to continue?`,
        continue: 'Continue',
        employeesLicenses: 'Employees Licenses',
      },
      errors: {
        header: 'User import error',
        length: {
          header: '-----------------\nUser "{{user}}" has the following error(s): \n',
          field: '  {{field}} cannot be longer than {{length}} characters.\n',
          fields: '  {{fields}} cannot be longer than {{length}} characters.\n',
          amount: '  {{field}} cannot be bigger than {{amount}}.\n',
        },
        invalidEmails: 'Invalid emails: \n "{{emails}}"',
        disposableDomains: 'Throwaway emails: \n "{{domains}}"',
        duplicatedEmails: 'These emails are duplicates in the uploaded file: \n "{{emails}}"',
        existedEmails:
          'Accounts with these emails already exist (possibly outside your team): \n "{{emails}}"',
        missedParams: '"{{params}}" are required attributes but they are missing in row "{{row}}"',
        invalidParam: 'Invalid {{param}} in row {{row}}',
        invalidCodes: 'Invalid codes: "{{codes}}"',
        trialUsersExceeded: 'Trial subscriptions can only have a maximum of {{max}} profiles.',
      },
    },
    referrals: {
      heading: 'Get your NFC-card for free',
      subtext:
        "Collect credits to get your free NFC card or Teams license now. All you have to do is invite your colleagues or friends to Lemontaps or write a Linkedin post. If you support us through multiple bonus promotions, we'll add up the points.",
      balance: {
        title: 'Your current credit balance',
        tooltip:
          'You can use your credit to buy Lemontaps products. Cash withdrawal is not possible.',
      },
      redeem: {
        title: 'Redeem your credits right away!',
        button: 'Redeem Credits',
        popup: {
          title: 'Redeem credits?',
          success:
            'Please contact us at <0>support@lemontaps.com</0> to let us know you want to redeem the credit.\nPlease mention the item you want to get.',
          failed: 'Your current balance is not sufficient for this item yet. Keep referring!',
        },
      },
      collect: {
        title: 'Collect Credits',
        email: {
          title: 'Invite your contacts',
          subtext:
            'Invite your colleagues, friends or contacts to Lemontaps with your business email and earn <0>2€ in credits</0> per verified registration.',
          tooltip:
            'Only accounts with business emails (no gmail, etc.) that have also completed the onboarding count.',
          placeholder: 'business e-mail',
          button: 'Send Invitation',
          copyButton: 'Copy sharable link',
        },
        linkedin: {
          title: 'Get 15€ credit for free',
          subtext:
            'Tag us on your next LinkedIn post about Lemontaps and get <0>15€ in credits</0> right away. <1></1>Just send us the link to the post to <2>contact@lemontaps.com</2>, and we will confirm your new credit.',
        },
      },
      toast: {
        emailError: 'Please insert a valid company email',
        emailSuccess: 'Email sent successfully',
        linkSuccess: 'Link copied to clipboard',
      },
      menuButton: 'Free NFC-Card',
      card: 'Get a Free NFC-Card',
    },
    selectFromList: 'Select from list',
    send: 'Send',
    hereIsMyContact: 'Here is my contact',
    myContact: 'My contact',
    profileImageUpdated: 'Profile image updated',
    bannerImageUpdated: 'Banner image updated',
    logoImageUpdated: 'Logo image updated',
    error: {
      general: 'Something went wrong, please reload the page and try again.',
      editBio: 'Error in saving Data.',
      defaultAddress: 'Unable to update default address template.',
      defaultLink: 'Unable to update default link.',
      defaultFile: 'Unable to update default file.',
      uploadFile: 'Unable to upload file.',
      updateFile: 'Unable to update file.',
      updateLink: 'Unable to update link.',
      deleteLink: 'Unable to delete link.',
      deleteFile: 'Unable to delete file.',
      uploadingImage: 'Error uploading image',
      updatingImage: 'Error updating image',
      updateAccounts: 'Failed to update accounts. Please try again!',
      errorDownloadQr: 'Maximum 10 profiles can be selected for this action.',
      successfullySent: 'Successfully sent',
      disposableEmail: 'Using a disposable email is not allowed!',
      invalidEmail: 'Please use a valid email format!',
      required: 'Required field',
      existedEmail: 'Email already in use! Please use another email.',
      socialEmail:
        'You are registered through a social provider. Log in there to change your email.',
      requiredFieldGeneral: 'Required field',
      invalidFieldGeneral: 'Invalid format',
      minOneFieldRequired: 'Minimum one field required',
      pleaseFillOutRequired: 'Please fill out the required fields',
      maxLength: 'Maximum {{value}} characters',
    },
    teaser: {
      starterBullets: [
        'Digital business card for mobile & desktop',
        'Upload unlimited links & files',
        'Apple Wallet, QR Code, QR Backgrounds ',
        'Analytics & Insights',
      ],
      proBullets: [
        'Central user management',
        'Use an individual company domain',
        'Subdivision into subgroups',
        'Corporate design profile',
        'Add Templates & Images ',
        'Distribute roles & rights',
        'Mass export of QR codes/wallet',
        'Contact capture and management',
        'AI business card scanner for leads',
        'Interfaces: SSO, MS AD, CRM possible',
        'Training videos & Prio support',
      ],
      enterpriseBullets: [
        'Whitelabel functionalities such as internal design, favicon, email editor, etc.',
        'Subdivision into subgroups with specific design & settings',
        'Translation of profile content into any language',
        'Lead reporting functions for trade fairs',
        'Personal consulting & onboarding',
        'Rollout & best-practice consulting',
        'Lemontaps Academy video series',
        'Dedicated support and SLA',
        'Custom code adjustments',
      ],

      starterShortDescr:
        'Create your digital business card profile for yourself easily & free of charge.',
      teamsShortDescr:
        'Use Lemontaps in a team (1-50 users) and according to your corporate guidelines.',
      enterpriseShortDescr:
        'Recommended for teams of 50 people or more with extended security, control and support functions.',

      testItXDaysForFree: 'Try {{days}} Days for Free',
      centralUserAdministration: 'Central User Administration',
      easilyDataOfAllEmployees:
        'Easily enter, edit, and manage data of all employees for profile creation.',
      leadRecording: 'Lead Recording',
      teamsEssential: 'Try Lemontaps Teams for free',
      noCredit: 'No credit card or cancellation required.',
      noInstallation: 'No installation required',
      contacts:
        'Contacts can be recorded digitally through the lead form on your profile or in your own view and automatic follow-up emails can be sent.',
      contactsLand:
        'Contacts are sorted chronologically by time and date in the contact management system. Here, further information can be added (such as notes and images) to avoid forgetting the person.',
      manageContacts: 'Manage Contacts',
      makeContacts: 'Take Contacts',
      packageComparison: 'Compare All Packages',
      starter: 'Starter',
      individualUser: 'For individual users',
      noPayment: 'Free forever. No credit card required.',
      currentPlan: 'Current Plan',
      basicFeature: 'Basic Features:',
      teamEssential: 'Essential for Teams',
      groupUsers: '1 - 50 users',
      peruserpermonth: 'Per user/month when payed yearly.',
      graduatedprices: 'Graduated prices for licenses and NFC cards.',
      costless: 'Try {{days}} days for free',
      allStarterFeatures: 'Starter features, plus:',
      allProFeatures: 'Teams features, plus:',
      allFeatures: 'All Teams Features, plus:',
      platformManagment: 'Management platform for central user administration',
      trading: 'Profile according to corporate branding',
      contact: 'Contact recording',
      teamsPro: 'Lemontaps Teams',
      fiftygroupUsers: 'Recommended for 50+ users',
      recievedError: 'Receive offer',
      allTeamsFeature: 'All Teams Features plus:',
      settelment: 'Consolidated into multiple units/locations',

      individualAdjustment: 'Individual adjustments like emails and internal design',
      standard: 'Default links/addresses templates',
      askUs: 'Ask us for an offer',
      hunderdsgroupUsers: 'Companies with 50+ users',
      allCompanyFeatures: 'All company features, plus:',
      premiumSupport: 'Premium support and SLA',
      comparison: 'For full feature comparison',
      popular: 'Most popular for teams',
      adminManagment: 'Admin management',
      efficientSolution:
        'The most efficient solution to equip your entire team with digital business cards. Try our most popular features for free for {{days}} days.',
      leadgenEfficientSolution:
        'The lead capture feature is included in the Lemontaps Teams package. You can also use Lemontaps Teams as an individual user. Try our most popular features for free for {{days}} days.',
      importantFunction: 'Important functions at a glance',
      centralUser: 'Central user management',
      easyData: 'Easily enter, edit, and manage data for all employees for profile creation.',
      corporateIdentity: 'Profile according to corporate identity',
      designDigitalProfile:
        'Design your digital profile according to your colors, shapes, logo, and standard background images.',
      individualProcessing: 'Individual processing rights',
      access: 'Decide who should have which access and processing rights.',
      leader: 'Lead capture',
      efficientContact:
        'Your team can efficiently contact, manage and send automatic follow-up emails via the profile.',
      myProfile: 'My profile',
      noCancellation: 'And no cancellation required',
      expired: 'Your trial period has expired.',
      moreInfo: 'More info on Teams packages',
      welcome: 'Welcome to Lemontaps Teams 🎉',
      companyName: 'What is the name of your organization?',
      potentialUser: 'How many potential users are there?',
      stratNow: 'Start now!',
      upgradeSubtext: 'or upgrade',
      salesSubtext: 'or contact sales',
      collectLeadsWithLt: 'Collect leads with Lemontaps',
    },
    upgradeTeaser: {
      upgradeYourPlan: 'Upgrade required.',
      contactUs: 'Contact us.',
      enableForTrial: 'Get access to this for the trial period!',
      feature: {
        leadGenInternalContactForm:
          'Configure which fields should be available internally for managing contacts.',
        vcardConfig: 'Configure which information to include in downloaded contacts',
        units:
          'Group users into units that can be managed by sub-admins. You can also configure unit-level configurations, such as a unit-level profile design. This feature is not available in your current plan.',
        leadgenMd:
          'Find all contacts/leads collected by all employees in one place using the contact form in the public profile. Edit and export the contacts. This feature is not available in your current plan.',
        leadgenIndividual:
          'Find all contacts/leads you have collected in the public profile using the contact form. This feature is not available in your current package.',
        themeLinks: 'Default links are not available in your current plan.',
        themeFiles: 'Default files are not available in your current plan.',
        themeAddresses: 'Default addresses are not available in your current plan.',
        dataTemplates:
          'Create templates for standard addresses, links, and files. If these templates are changed here, the changes will be immediately reflected in all profiles that have added this template. This feature is not available in your current plan.',
        globalData: 'Create links, files, or addresses that are visible on all profiles.',
        emailTemplates:
          'Customize the emails sent out from Lemontaps with your own design and text. You can edit the invitation email and the emails for the contact form.This feature is not available in your current plan.',
        leadGenContactForm:
          'Customize the contact form on the profile page of your team members. Decide which fields are mandatory, which fields are optional, and which fields are not displayed. This feature is not available in your current plan.',
        mdAnalytics: 'Analyze cross-profile aggregated statistics on the usage of your employees.',
        analyticsIndividual: 'Analyze statistics about your profile.',
        internalDesign: 'The Internal Design is not available in your current plan.',
        unitHierarchies: {
          editRightsTheme:
            'Override the edit rights for the theme. This feature is not available in your current plan.',
          editRights:
            'Override the edit rights for this unit. This feature is not available in your current plan.',
          editRightsGeneral:
            'Decide which unit-specific settings may be managed by unit admins themselves. This feature is not available in your current plan.',
          profileDesign:
            'Override the profiledesign for this unit. This feature is not available in your current plan.',
        },
        translations:
          'Translate the digital business card profiles of your team. You can translate the standard text, contact information, link names, links and file names. You can also decide not to show information in certain languages. This feature is not available in your current plan.',
        businessCardScanner:
          'Digitize and import contact data from paper business cards to Lemontaps, CRM or Outlook. In compliance with GDPR and in a matter of seconds.',
        visibilityConfig: 'Select to hide certain pages and content elements.',
      },
    },
    globalSettings: 'Further global settings',
    integrations: {
      title: 'Integrations',
      subtitle:
        'Lemontaps can be optimally integrated into your system landscape. The following integrations can be optionally added.',
      connect: 'Connect',
      configure: 'Configure',
      connected: 'Connected',
      inProgress: 'In Progress',
      customDomain: {
        title: 'Custom Domain',
        description: 'Connect your digital profiles to your own subdomain/URL.',
        popupDescription: 'Choose a subdomain you want to use. Then write an email to {{email}}.',
      },
      sso: {
        title: 'SSO Login',
        description: 'Integrate a secure SSO/SAML corporate access.',
      },
      activeDirectory: {
        title: 'Active Directory',
        description: 'Profile data can be synchronized with your Active Directory.',
      },
      crm: {
        title: 'CRM',
        description: 'Synchronize collected contacts.',
      },
    },
    contactsTable: 'Contacts Table',
    businessCardScanner: {
      title: 'Business Card Scanner',
      infoText: 'Point at a paper card or QR-Code, then take a photo with the button below',
      infoTextFront: 'Capturing front',
      infoTextBack: 'Capturing back',
      enterMinOne: 'Fill in at least one field to continue',
      infoEmailWillBeSent:
        'When you click "save", an email with your contact info will be sent to this contact',
      cameraNotAvailable:
        'Something went wrong. Check if camera access is granted or camera is not in use by other application.',
      fileCaptureError: 'Error. Try again.',
      addContactSuccess: 'Contact saved',
      addContactError: 'Error saving contact',
      upload: 'Upload',
      loadingTitle: 'Analyzing business card',
      loadingSubtitle: 'It usually takes a couple of seconds',
      businessCardTitle: 'Select correct values',
      businessCardSubtitle: 'You can also edit the attributes later.',
      retake: 'Retake',
      next: 'Next',
      sendContactToEmail: `Send my business card to {{email}}`,
      gdprTitle: 'GDPR',
      gdprSubtitle: 'Please select the legal basis for saving this contact.',
      noFieldsAvailable:
        'No attributes could be recognized. Click on "Next" to create the contact manually.',
      fileSelectSizeError: 'Please select a file that is smaller than 10MB.',
      fileSelectMaxFileSelectError: 'Please select a maximum of {{count}} files',
      permissionDenied: {
        preText: 'Follow instructions to enable camera access',
        desktop: {
          firefox:
            "Click the shield icon in the address bar, select 'Permissions', find 'Use the Camera', and choose 'Allow'. Refresh the page afterwards.",
          chrome:
            "Click the padlock icon in the address bar, select 'Camera', choose 'Allow', and then refresh the page.",
          safari:
            "Click the 'aA' icon in the address bar, choose 'Website Settings', select 'Allow' next to Camera, and then refresh the page.",
          edge: "Click the padlock icon in the address bar, select 'Camera', choose 'Allow', and then refresh the page.",
          opera:
            "Click the padlock icon in the address bar, select 'Camera', choose 'Allow', and then refresh the page.",
        },
        mobile: {
          firefox:
            "Click the shield icon in the address bar, select 'Permissions', find 'Use the Camera', and choose 'Allow'. Refresh the page afterwards.",
          chrome:
            "Tap on the three dots menu or the 'More' icon, go to 'Settings', find 'Site Settings', tap on 'Camera', and ensure it's set to 'Ask first' or 'Allowed'.",
          safari:
            "Go to the iOS 'Settings' app, scroll down to 'Safari', tap on 'Camera', and select 'Allow'",
          edge: "Tap on the three dots menu, go to 'Settings', tap 'Site permissions', choose 'Camera', and set it to 'Ask before accessing'.",
          opera:
            "Open the menu, go to 'Settings', find 'Site Settings', select 'Camera', and set it to 'Ask first' or 'Allowed'.",
        },
      },
      showFront: 'Show front',
      showBack: 'Show back',
      captureFront: 'Capture front',
      captureBack: 'Capture back',
    },
    freeTrialBanner: {
      ongoing: {
        line1_one: 'You have {{count}} day remaining of your free {{days}}-day trial of Lemontaps.',
        line1_other:
          'You have {{count}} days remaining of your free {{days}}-day trial of Lemontaps.',
        line2:
          ' to continue to use the Teams features of Lemontaps after the trial period has expired. <0>What happens after my trial period?</0>',
      },
      finished: {
        line1: 'Your trial has expired',
        line2:
          ' to regain access to all Teams features. Your profile and all profiles created through the management dashboard have been downgraded to the Starter plan.',
      },
    },
    mobilePopupText:
      'Browser width is too small. Please open this page on a desktop device or resize your browser to at least {{pixel}}px to access the management platform.',
    onboardingReferrer: {
      google: 'Google',
      linkedin: 'LinkedIn',
      instagram: 'Instagram',
      referral: 'Recommendation / Referral',
      event: 'Event',
      email: 'E-Mail',
      friends: 'Friends',
    },
    option: 'Option',
    custom: 'Custom',
    scannable: 'Scannable',
    contactFormEdit: {
      resetToDefaultLabel: 'Default name:',
      saveTooltip: 'At least one field must be marked as required.',
      fullName: 'Name',
      required: 'Required',
      optional: 'Optional',
      notShown: 'Not displayed',
      shown: 'Displayed',
      title: 'Configuration of the contact form',
      businessCardUrls: 'Business Card Image',
      description: 'These settings will be applied to all profiles of your team.',
      atLeastOneRequired: 'At least one field must be marked as required.',
      usedInExternal: 'You cannot deselect a field that is still visible in the external form.',
      deletingUsedInExternal: 'The field you want to delete is still visible on the external form.',
      external: {
        title: 'External form',
      },
      internal: {
        title: 'Internal form',
        deletePopupTitle: 'Are you sure you want to delete the field?',
        deletePopupBody:
          'All information that has been captured with this field is going to be deleted. This cannot be undone.',
        questionTitle: 'Field Name',
        answerOptions: 'Field Options',
        addOption: 'Add Option',
        editField: 'Edit Field',
        deleteField: 'Delete Field',
        editSystemField: 'Edit system field label',
        customFields: {
          text: {
            title: 'Text',
            tooltip: 'A single line text input',
          },
          textarea: {
            title: 'Textarea',
            tooltip: 'A multiline text input',
          },
          checkbox: {
            title: 'Checkbox',
            tooltip: 'Multi-select via checkboxes',
          },
          dropdown: {
            title: 'Dropdown',
            tooltip: 'Single-select via a dropdown',
          },
          radio: {
            title: 'Radio',
            tooltip: 'Single-select via a dropdown',
          },
        },
        alert: {
          title: 'Business card scanner fields.',
          body: 'Those fields marked with "Scannable" can be scanned via the business card scanner.',
        },
      },
    },
    deleteAccount: {
      title: 'Delete Account',
      pageTitle: 'Danger Zone',
      buttonText: 'Delete My Account',
      popupTitle: 'Are you sure you want to delete your account? You cannot undo this!',
      popupBody:
        'All data associated with this account will be permanently deleted. This cannot be undone.',
      popupEmailLabel: 'Enter the email address that you are registered with',
      success: 'Your account has been deleted.',
      emailMismatch: 'The email does not match the one you are registered with.',
      confirm: 'Yes, delete account',
    },
    emailTemplates: {
      upgradeAlertTitle: 'Limited Email Editor',
      upgradeAlertBody:
        'You do not have full editing rights for the email editor and therefore cannot change the font and colors and cannot upload images or remove the Lemontaps branding.',
      noSetPasswordUrlAlert:
        'The placeholder $SET_PASSWORD_URL$ is not included in the template currently. If you do not include this URL in the email, the invited user will not be able to set a password and login to their account.',
      selectTemplate: 'Select E-Mail',
      selectLanguage: 'Select Language',
      errorUploadingImage: 'Error uploading image',
      errorSaving: 'Error while saving',
      errorSendingTestEmail: 'Error while sending test email',
      successfullySaved: 'Successfully saved',
      testEmailSuccessfullySent: 'Test email successfully sent',
      standardLocale: 'Default',
      enLocale: 'English',
      deLocale: 'German',
      'welcome-email-template': 'Invitation email to employees',
      'contact-share-email-template': 'Contact form: Email to new contacts',
      'contact-received-email-template': 'Contact form: Internal notification',
      'generic-status-email': 'General purpose informational email',
      'qr-bg-complete-email-template': 'Status Email: QR-Code Background Generation',
      'qr-complete-email-template': 'Status Email: QR-Code Generation',
      'card-order-confirmation-email-template': 'Card order confirmation email',
      'referral-email-template': 'Referral Invitation email',
      'referral-referrer-template': 'Referral completed: Email to referrer',
      'referral-recipient-template': 'Referral completed: Email to recipient',
      'wallet-pass-mail-template': 'Wallet email',
      'onboarding-email-template': 'Onboarding email',
      testEmailSendPopupTitle: 'Send test email to',
      testEmailSendPopupSubtitle:
        "The dynamic information in the email is based on your profile's data.",
      sendTestEmail: 'Send test email',
      content: 'Content',
      layout: 'Layout',
      columns: 'Columns',
      column: 'Column',
      editView: 'Edit View',
      desktopPreview: 'Desktop Preview',
      mobilePreview: 'Mobile Preview',
      duplicateTitle: 'Duplicate Contents To',
      attachments: 'Attachments',
      attachmentKeys: {
        'offline-apple-wallet': 'Offline Apple Wallet',
        'online-apple-wallet': 'Online Apple Wallet',
      },
    },
    export: {
      refresh: 'Refresh',
      title: 'Export',
      infoCard: {
        title: 'Export file is being generated',
        body: 'This may take some time. We will notify you by email as soon as it is ready for export.<0/><1>Note</1>: It may happen that the email is blocked by your company IT. In this case, please check the status directly on this exports page.',
      },
      emptyCard: {
        title: 'No files found',
        body: 'You have not exported any files in the past 14 days.',
      },
      fileCard: {
        errorTitle: 'Export failed...',
        runningTitle: 'Export file is being generated...',
        date: 'Exported {{date}}',
        status: {
          running: 'Export running....',
          finished: 'Export completed',
          failed: 'Failed',
        },
        action: {
          download: 'Download',
          retry: 'Try again',
          contact: 'Unexpected error. Please contact: <0>support@lemontaps.com</0>',
        },
        header: {
          qrPlain_one: '{{count}} {{type}} QR-Code',
          qrPlain_other: '{{count}} {{type}} QR-Codes',
          qrBg_one: '{{count}} QR-Code Background',
          qrBg_other: '{{count}} QR-Code Backgrounds',
        },
      },
    },
    idp: {
      setupScim: 'Setup SCIM',
      teaserDescription: 'Configure SSO and User Provisioning. Works with most Identity Providers.',
      identityProvider: 'Identity Provider',
      listPageTitle: 'Choose an Identity Provider',
      listPageDescription:
        'Integrate your Identity Provider to set up Single Sign-On and User Provisioning.',
      settingsPageDescription:
        'Manage the integration of your Identity Provider. Configure Single Sign-On and SCIM provisioning.',
      choose: 'Choose',
      addIdentityProviderDirectory: 'Add Identity Provider directory',
      directoryName: 'Directory name',
      createSuccess: 'Identity provider directory created successfully',
      createError: 'Error creating Identity Provider directory',
      updateSucess: 'Identity provider directory updated successfully',
      updateError: 'Error updating Identity Provider directory',
      settings: 'Settings',
      renameDirectory: 'Rename directory',
      dirName: 'Directory name',
      deleteConfiguration: 'Delete configuration',
      edit: 'Edit',
      try: 'Try',
      deleteSuccess: 'Identity provider directory disconnected successfully',
      deleteError: 'Error disconnecting Identity Provider directory',
      deleteMessage: 'Are you sure you want to disconnect this directory?',
      disconnect: {
        title: 'Before you disconnect',
        description: 'Before you can disconnect your Identity Provider, you need to:',
        bulletPoints:
          '• Delete SSO configuration\n• Delete user provisioning configuration\n• Link domains to a diffrent director',
        anchorText: 'Learn more about how to disconnect',
      },
      scim: {
        inviteUserUponProvisioning: 'Send invite when new user is provisioned',
        title: 'SCIM Provisioning',
        description:
          'Here you can set up and manage automatic user provisioning and deprovisioning.',
        isActive:
          'SCIM is active. To manage provisioning settings, manage the SCIM configuration in your Identity Provider.',
        setupProvisioning: 'Start the setup',
        syncedUsers: 'Synced users',
        regenerateApiKey: 'Regenerate API key',
        deleteConfigurationConfirmation: 'Are you sure you want to delete this configuration?',
        deleteConfigurationSuccess: 'SCIM configuration deleted',
        deleteConfigurationError: 'Error deleting SCIM configuration',
        updateConfigurationError: 'Error updating SCIM configuration',
        stupScimProvisioning: 'SCIM setup',
        saveConfig: 'Save SCIM configuration',
        learnMoreAboutScim: 'Learn more about SCIM provisioning',
        regenerateKey: 'Regenerate key',
        steps: {
          beforeYouBegin: {
            stepperTitle: 'Before you begin',
            title: 'Before you begin',
            description:
              'Before you set up user provisioning via SCIM, we recommend you: \nCreate test accounts and groups in your Identity Provider to prevent existing users from losing product access. When you connect your Identity Provider and sync for the first time, you can use these test accounts and groups to ensure everything works.',
            description2: 'After setup is complete, you will be able to:',
            description3:
              '• Manage user details from within your Identity Provider \n• Automatically provision new users \n• Automatically deprovision users',
          },
          setupScim: {
            stepperTitle: 'Setup SCIM',
            title: 'Details',
            description:
              'To set up user provisioning via SCIM and connect your Identity Provider with Lemontaps, copy the SCIM Base URL and the API Key into your Identity Provider. Enter "userName" as the unique identifier if asked for that value.',
            scimBaseUrl: 'SCIM base URL',
            apiKey: 'API key',
            apiKeyHelperText:
              "We don't save your API key. We recommend you store it somewhere safe.",
          },
          saveConfig: {
            stepperTitle: 'Save configuration',
            title: 'Save SCIM configuration',
            description:
              'You just finished setting up user provisioning via SCIM. Now, you can update synced groups and users to automatically access Atlassian products.',
          },
          regenerate: {
            stepperTitle: 'Regenerate Key',
            title: 'Regenarate directory API key',
            description:
              'Regenarate a new API key will disable the existing key. Update the credentials in your Identity Provider with the new details to continue syncing with your Identity Provider,',
          },
          apiKeys: {
            stepperTitle: 'API Keys',
          },
        },
      },
      sso: {
        connectionEnabled: 'SSO enabled',
        cardTitle: 'Single Sign-On',
        cardDescription: 'Here you can set up and manage Single Sign-On (SSO).',
        buttonText: 'Set up Single Sign-On',
        setupSaml: 'Set up Single Sign-On',
        pageTitle: 'SSO Configuration',
        entityId: 'Entity Id',
        singleSignOnUrlForCopy: 'Single Sign-On URL',
        signInUrl: 'Sign-in URL',
        signOutUrl: 'Sign-out URL',
        certificate: 'x509 Certificate',
        signInUrlHelper: 'signInUrlHelper',
        signOutUrlHelper: 'signOutUrlHelper',
        certificateHelper: 'certificateHelper',
        selectDomains: 'Select domains',
        oktaDomain: 'Okta Domain',
        oktaDomainHelper: 'oktaDomainHelper',
        clientID: 'Client ID',
        clientIDHelper: 'clientIDHelper',
        clientSecret: 'Client Secret',
        clientSecretHelper: 'clientSecretHelper',
        entraIdDomain: 'Entra ID Domain',
        entraIdDomainHelper: 'entraIdDomainHelper',
        metaDataUrl: 'Metadata URL',
        metaDataUrlHelper: 'metaDataUrlHelper',
        identityAPI: 'Identity API',
        azureActiveDirectoryV1: 'Azure Active Directory v1',
        microsoftIdentityPlatformV2: 'Microsoft Identity Platform v2',
        updateSuccess: 'SSO configuration updated successfully',
        updateError: 'Error updating SSO configuration',
        deleteSuccess: 'SSO configuration deleted successfully',
        deleteError: 'Error deleting SSO configuration',
        deleteConfigurationConfirmation: 'Are you sure you want to delete this configuration?',
        learnMoreAboutSaml: 'Learn more about Single Sign-On',
        learnMoreAboutDomains: 'Learn more about linking domains',
        copySection: {
          title: 'Copy URL to Identity Provider',
          description: 'Copy and paste these service provider URLS to your Identity Provider.',
        },
        editSection: {
          title: 'Edit SSO configuration',
          description: 'Copy these SSO details from your Identity Provider and paste them here.',
        },
        domainSection: {
          title: 'Domains',
          description: 'Copy and paste these service provider URLS to your Identity Provider.',
        },
        steps: {
          beforeYouBegin: {
            stepperTitle: 'Before you begin',
            title: 'Before you begin',
            description: 'Please note the following:',
            description2:
              '• SSO allows your users to authenticate with company credentials on Lemontaps.\n• Make sure the IdP side is also correctly configured.',
            description3:
              'After the setup wizard, the SSO connection can be tested. Only thereafter SSO should be activated.',
            selectStrategy: 'Select strategy',
          },
          copyUrls: {
            stepperTitle: 'Copy values',
            title: 'Copy values',
            description: 'What you need to configure SSO in your Identity Provider:',
            description2:
              '• Service provider entity URL\n• Service provider assertion consumer service URL\n• Public x509 certificate',
            description3: 'You can refer back to this page at any time.',
          },
          addSaml: {
            stepperTitle: 'Enter values',
            title: 'Enter values',
            description: 'Enter the required values here:',
            setupUsingMetadata: 'Simply enter the metadata URL of your Identity Provider',
            or: 'OR',
            setupManually: 'Enter manually',
          },
          linkDomain: {
            stepperTitle: 'Link domain',
            title: 'Link domain',
            description: 'Link at least one domain to this SSO configuration.',
          },
          save: {
            stepperTitle: 'Save and continue',
            title: 'Save configuration',
            description: 'You just finished setting up SSO. Now you can enforce SSO.',
          },
        },
      },
    },
    crmIntegration: {
      title: 'CRM Integration',
      description:
        'Connect Lemontaps to your CRM to auto or manual sync your Lemontaps contacts as CRM Contacts or Leads. Please read the help documentation to see which attributes will be pushed to your CRM.',
      settingsCard: {
        title: 'General CRM Integration Settings',
        autoSync:
          'Auto Export: Enable this workflow to automatically send new contacts to the CRM instantly upon creation.',
        manualSync:
          'Manual Push: Enable this workflow to choose manually which contacts to send to your CRM.',
        headerSyncType: 'Auto or Manual Export',
        headerUseLeads: 'Convert to Contacts or Leads',
        contacts: 'Contacts: Convert Lemontaps contacts into CRM Contacts',
        leads: 'Leads: Convert Lemontaps contacts into CRM Leads',
      },
      crmListCard: {
        title: 'Manage your CRM Integrations',
        subheader:
          'We support the following CRM systems. Click on a CRM system to start configuring it or to edit it.',
      },
      upgradeAlert: {
        title: 'Connect to your CRM account to sync Lemontaps contacts as CRM Leads.',
        description: 'Choose between an Auto Sync or Manual Push Option',
        learnMore: 'Learn more about the CRM integration',
      },
      exportStarted: 'Export to {{crm}} started on {{datetime}}',
      exported: 'Exported to {{crm}} on {{datetime}}',
      exportFailed: 'Export to {{crm}} failed on {{datetime}}',
      exportFailedDuplicate: 'Export to {{crm}} failed on {{datetime}} due to duplicate contact',
      status: {
        notConnected: 'Not connected',
        added: 'Needs Configuration',
        authorized: 'Needs Configuration',
        invalid: 'Invalid Configuration',
        callable: 'Connected',
        disabled: 'Disabled',
      },
    },
    selectAllTexts: {
      profile: 'Select all {{count}} profiles?',
      lead: 'Select all {{count}} contacts?',
      unit: 'Select all {{count}} units?',
    },
    dropzone: {
      title: 'Click to upload or drag and drop',
      errors: {
        'file-invalid-type': 'Invalid file type. Accepted formats: {{formats}}',
        'file-too-large': 'File is too big (<{{maxSize}}MB for .{{ext}}).',
        'file-too-small': 'File is too small.',
        'too-many-files': 'Too many files uploaded.',
      },
    },
    filter: {
      title: 'Filters',
      addFilter: 'Add filter',
      searchLabel: 'Filter by...',
      operators: {
        is_in: 'is in',
        is_not_in: 'is not in',
        is_equal: 'is equal to',
        is_not_equal: 'is not equal to',
        is_like: 'is like',
        is_not_like: 'is not like',
        is_greater: 'is greater than',
        is_greater_or_equal: 'is greater or equal to',
        is_less: 'is less than',
        is_less_or_equal: 'is less or equal to',
      },
    },
    ariaPreviewProfile: 'Preview profile',
    ariaCopyProfileUrl: 'Copy profile URL',
    ariaDownloadProfileQrCode: 'Download profile QR code',
    ariaViewAllAnalytics: 'View all analytics',
    ariaviewAllLeads: 'View all leads',
    ariaSendInviteAfterUserCreate: 'Send invite after user creation',
    ariaSendInviteToSelected: 'Send invite to selected users',
    ariaBulkEditSelected: 'Bulk edit selected users',
    ariaExportSelected: 'Export selected',
    ariaUnitAssignSelected: 'Assign selected users to unit',
    ariaDeleteNfcCard: 'Delete {{name}} NFC card',
    ariaShareAnalytics: 'Share analytics',
    ariaOpenDateRangeSelector: 'Open date range selector. Current Selection: ',
    ariaCloseDrawer: 'Close drawer',
    ariaBulkOverride: 'Selected bulk option: Override',
    ariaBulkDelete: 'Selected bulk option: Delete',
    ariaShareQrInfo: 'Share QR instructions',
    ariaQrDownload: 'Download {{type}} QR code',
    ariaQrDownloadAppleWallet: 'Download Apple Wallet {{type}} QR code',
    ariaQrDownloadGoogleWallet: 'Download Google Wallet {{type}} QR code',
    ariaShareWhatsApp: 'Share via WhatsApp',
    ariaShareEmail: 'Share via Email',
    ariaShareLinkedIn: 'Share via LinkedIn',
    ariaWebShare: 'Share via Web Share API',
    ariaSaveContactColor: 'Save contact color - {{color}}',
    ariaFilesColor: 'Files color - {{color}}',
    ariaGenericWebsiteLinkColor: 'Generic website link color - {{color}}',
    ariaCoverImageSettings: 'Open cover image settings',
    ariaProfileImageSettings: 'Open profile image settings',
    ariaLogoImageSettings: 'Open logo settings',
    ariaDelete: 'Delete {{name}}',
    ariaEdit: 'Edit {{name}}',
    ariaAddLinkTemplate: 'Add link template',
    ariaAddAddressTemplate: 'Add address template',
    ariaAddFileTemplate: 'Add file template',
    ariaClear: 'Clear',
    ariaSelect: 'Select {{name}}',
    ariaUnitViewMembers: 'View members of unit {{name}}',
    ariaSettings: '{{name}} - reveal more options',
    ariaProfileStatusActions: 'View status actions of {{name}}',
    ariaEditUser: '{{name}} - edit',
    ariaEditContact: '{{name}} - edit',
    ariaBusinessCardImageSettings: 'Open business card image settings',
    ariaCloseScanner: 'Close business card scanner',
    ariaGoBackToPreviewsScreen: 'Go back to previews screen',
    ariaBusinessCardImagesToggler: 'Toggle business card images visibility',
    ariaRefreshCampaigns: 'Refresh campaigns',
    ariaSaveCrmConfiguration: 'Save CRM configuration',
    ariaOpenIdpSettings: 'Open Identity Provider settings',
    ariaOpenScimSettings: 'Open SCIM settings',
    ariaOpenSsoSettings: 'Open SSO settings',
    ariaClickToCopy: 'Click to copy',
    ariaRedirectBusinesssCardTo: 'Redirect business card to {{link}}',
    ariaRefreshCrmConfigurations: 'Refresh CRM configurations',
    ariaToggleVCardFieldVisibility: 'Toggle "{{field}}" field visibility',
    ariaHideLinkFromProfile: '{{link}} - hide on business card',
    ariaEditMessage: '{{name}} - Edit',
    ariaDeleteMessage: '{{name}} - Delete',
    ariaHideMessage: '{{name}} - Hide on business card',
    ariaShowMessage: '{{name}} - Show on business card (currently not shown)',
    ariaRedirectMessage: '{{name}} - Redirect business card to this link.',
    ariaRedirectOffMessage: '{{name}} - Deactivate redirect behavior.',
    ariaDisabledFieldHelpButton: '{{fieldName}} - Help',
    ariaLeadsTableCaption: 'Leads table, column headers with buttons are sortable',
    ariaUnitsTableCaption: 'Units table, column headers with buttons are sortable',
    ariaProfilesTableCaption: 'Profiles table, column headers with buttons are sortable',
    ariaThemeLinkFileEdit: '{{name}} (globally defined by an admin) - Edit',
    showNextPage: 'Show next page',
    showPreviousPage: 'Show previous page',
    ariaShowColumn: '{{name}} - Show column',
    ariaToggleVisibilityElement: '{{name}} - Visible',
    ariaOpenActionFor: 'Open actions for {{name}}',
    ariaDnd: {
      draggableItem: 'Draggable item',
      draggableDescription:
        'Press space bar to start a drag. When dragging you can use the arrow keys to move the item around and escape to cancel. Some screen readers may require you to be in focus mode or to use your pass through key.',
      onDragStart:
        'You have lifted the item "{{itemName}}" in position {{position}}. Use the arrow keys to move it.',
      onDragUpdate:
        'You have moved the item from position {{fromPosition}} to {{toPosition}}. Press the spacebar to drop it.',
      onDragEnd:
        'You have dropped the item {{itemName}}. You have moved the item from position {{fromPosition}} to {{toPosition}}.',
    },
    ariaLeadCustomFieldOption: 'Lead custom field option',
  },
};
